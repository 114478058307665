import Swal from 'sweetalert2';

export const AlertInfo = (icon, title, text = '') => {
    return Swal.fire({
        icon,
        title,
        text,
        confirmButtonColor: '#d33',
    })
}

export const AlertConfirm = (icon, title, text = '', yesButton = 'SIM', notButton = 'CANCELAR', notButtonColor = '#ccc') => {
    return Swal.fire({
        icon,
        title,
        text,
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: notButtonColor,
        confirmButtonText: yesButton,
        cancelButtonText: notButton,
    })
}

export const AlertQuestion = (title, txt, inputValue, required = true, notNull = "Justificativa invalida") => {
    const isRequired = (value) => {
        if (required) {
            if (!value) return notNull;
        }
    }

    return Swal.fire({
        title,
        input: 'text',
        inputLabel: txt,
        inputValue: inputValue,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        inputValidator: isRequired,
    })
}

export const AlertOptionsParcelas = () => {
    return Swal.fire({
        title: "SELECIONE A QUANTIDADE DE PARCELAS",
        input: "select",
        inputOptions: {
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "4",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
            11: "11",
            12: "12",
        },
        inputPlaceholder: "SELECIONE A QUANTIDADE",
        showCancelButton: true,
    });
}