import axios from "axios";

export const apiUrl = 'https://apilinkacademia.renovetecnologia.com';
// export const apiUrl = 'http://localhost:7777';

export const api = axios.create({
    baseURL: apiUrl
});

api.interceptors.request.use(config => {
    const infoLogin = JSON.parse(sessionStorage.getItem("infoLogin"));
    config.headers.token = (infoLogin && infoLogin.token) ? infoLogin.token : '';
    return config;
});