import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ListActions from "../../components/Lists/ListActions";
import Modal from "../../components/Modal/Modal";
import Input from '../../components/Inputs/Input';
import { api } from "../../services/api";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { converterMoeda, displayMoney } from "../../services/displayValue";

export default function Planos({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_academia_plano] = useState(infoLogin.id_academia_usuario || 0);
    const [planos, setPlanos] = useState([]);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [id_plano, setIdPlano] = useState(null);
    const [plano, setPlano] = useState("");
    const [valor_plano, setValorPlano] = useState("");

    function deletePlano(item) {
        AlertConfirm('', 'REMOVER PLANO?').then(async res => {
            if(res.isConfirmed){
                const { id_plano = 0 } = item;
                const response = await api.post(`deleteplano`, { id_plano });
                if(response && response.status === 200 && response.data){
                    if(response.data.success) AlertInfo('success', 'PLANO REMOVIDO');
                    else AlertInfo('error', response.data.message);
                }
                loadPlanos();
            }
        });
    }

    async function loadPlanos() {
        try {
            const response = await api.post(`/planos`, { id_academia_plano });
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo('error', response.data.message);
                else setPlanos(response.data);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
    }

    function editPlano(item) {
        setIdPlano(item.id_plano);
        setPlano(item.plano);
        setValorPlano(displayMoney(item.valor_plano));
        setOpenModalAdd(true);
    }

    async function addPlano() {
        const data = { id_academia_plano, plano, valor_plano: converterMoeda(valor_plano) };
        const channel = id_plano ? 'updateplano' : 'createplano';
        const dataPlano = id_plano ? { id_plano, data } : data;
        const response = await api.post(channel, dataPlano);
        if(response && response.status === 200 && response.data){
            if(response.data.error){
                AlertInfo('error', response.data.message);
            }else{
                setOpenModalAdd();
                setIdPlano(null);
                setPlano("");
                setValorPlano("");
                loadPlanos();
            }
        }
    }

    useEffect(() => {
        loadPlanos();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="PLANOS"
            maxWidth="sm"
        >
            <Modal 
                open={openModalAdd} 
                setOpen={setOpenModalAdd} 
                title="ADICIONAR PLANO"
            >
                <Input
                    id="plano"
                    label="NOME PLANO"
                    value={plano}
                    setValue={setPlano}
                    next="valor_plano"
                />
                <br/><br/>
                <Input
                    id="valor_plano"
                    label="VALOR PLANO"
                    value={valor_plano}
                    setValue={setValorPlano}
                />
                <br/><br/>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button
                        variant="contained"
                        color='success'
                        onClick={() => addPlano()}
                    >
                        ADICIONAR
                    </Button>
                </div>
            </Modal>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    color='success'
                    onClick={() => setOpenModalAdd(true)}
                >
                    ADICIONAR
                </Button>
            </div>
            <ListActions
                array={planos}
                keyItem="plano"
                secondary="valor_plano"
                emptyText='NENHUM PLANO CADASTRADO'
                deleteFunc={deletePlano}
                editFunc={editPlano}
            />
        </Modal>
    );
}