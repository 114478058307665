import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Usuarios from './pages/Usuarios/Usuarios';
import CadastroUsuario from './pages/Usuarios/CadastroUsuario';
import Alunos from './pages/Alunos/Alunos';
import CadastroAluno from './pages/Alunos/CadastroAluno';
import Produtos from './pages/Produtos/Produtos';
import CadastroProduto from './pages/Produtos/CadastroProduto';
import Definir from './pages/Definir/Definir';
import Venda from './pages/Venda/Venda';
import Mensalidades from './pages/Mensalidades/Mensalidades';
import ContasPagar from './pages/ContasPagar/ContasPagar';
import Caixa from './pages/Caixa/Caixa';

const isAuthentication = () => {
    const infoLogin = sessionStorage.getItem('infoLogin') || null;
    if(infoLogin){
        return true;
    }else{
        return false;
    }
};

// NEXT FETURE
// const isAuthorized = () => {
//     const infoLogin = sessionStorage.getItem('infoLogin') || null;
//     if(infoLogin){
//         const permissions = String(infoLogin.permissoes_usuario).split(',');
//         //
//     }
// }

const PrivateRoute = (props) => {
    const { component: Component } = props;
    return isAuthentication() ? <Component/> : <Navigate replace to={"/login"} />
};

export default function MyRoutes() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/usuarios" element={<PrivateRoute component={Usuarios} />} />
                <Route path="/cadastrousuario" element={<PrivateRoute component={CadastroUsuario} />} />
                <Route path="/alunos" element={<PrivateRoute component={Alunos} />} />
                <Route path="/cadastroaluno" element={<PrivateRoute component={CadastroAluno} />} />
                <Route path="/produtos" element={<PrivateRoute component={Produtos} />} />
                <Route path="/cadastroproduto" element={<PrivateRoute component={CadastroProduto} />} />
                <Route path="/definir" element={<PrivateRoute component={Definir} />} />
                <Route path="/venda" element={<PrivateRoute component={Venda} />} />
                <Route path="/mensalidades" element={<PrivateRoute component={Mensalidades} />} />
                <Route path="/pagar" element={<PrivateRoute component={ContasPagar} />} />
                <Route path="/caixa" element={<PrivateRoute component={Caixa} />} />
                
                <Route path="/" element={<PrivateRoute component={Home} />} />
            </Routes>
        </BrowserRouter>
    );
}