import { Paper } from "@mui/material";
import { useTheme } from "@mui/material";
import { FaCalendarTimes, FaCalendarDay, FaCalendarCheck, FaCalendarAlt } from 'react-icons/fa';
import { FaDollarSign, FaSearchPlus, FaSearchMinus } from "react-icons/fa";
import './card.css';

export default function Card({title, label, icon, color = 'secondary', ...rest}) {
    const theme = useTheme();
    const ListIcon = {
        atraso: FaCalendarTimes,
        pendente: FaCalendarDay,
        recebido: FaCalendarCheck,
        total: FaCalendarAlt,
        plus: FaSearchPlus,
        minus: FaSearchMinus,
        dolar: FaDollarSign
    };
    const ActiveIcon = ListIcon[icon];

    return(
        <div {...rest}>
            <Paper 
                elevation={8} 
                style={{backgroundColor: theme.palette[color].main}} 
                sx={{
                    ':hover': {
                        boxShadow: 20
                    },
                }}
            >
                <div className="containerPaperCard">
                    <div>
                        <span>{title}</span>
                        <h1>{label}</h1>
                    </div>
                    <ActiveIcon size={60} color={theme.palette.secondary.main} />
                </div>
            </Paper>
        </div>
    );
}