import { FaEdit, FaTrash } from 'react-icons/fa';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@emotion/react';

export default function ListActions({array, keyItem, secondary = null, emptyText = 'NENHUM ITEM CADASTRADO', editFunc = null, deleteFunc = null}) {
    const theme = useTheme();

    return (
        <List>
            {array.map((value, index) => (
                <ListItem
                    key={`list${index}`}
                    disableGutters
                    secondaryAction={
                        <>
                            {editFunc && (
                                <IconButton aria-label="edit" onClick={() => editFunc(value)}>
                                    <FaEdit color={theme.palette.info.main} />
                                </IconButton>
                            )}
                            {deleteFunc && (
                                <IconButton aria-label="delete" onClick={() => deleteFunc(value)}>
                                    <FaTrash color={theme.palette.error.main} />
                                </IconButton>
                            )}
                        </>
                    }
                >
                    <ListItemText primary={value[keyItem]} secondary={secondary ? value[secondary] : ""} />
                </ListItem>
            ))}
            {array.length === 0 && (
                <ListItem>
                    <ListItemText primary={emptyText} style={{textAlign: 'center'}} />
                </ListItem>
            )}
        </List>
    );
}