import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FaTrash, FaFileInvoiceDollar } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Template from "../../template/Template";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import SelectSimple from '../../components/Selects/SelectSimple';
import { AlertConfirm, AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { arrayUf } from '../../data/uf';
import SelectCity from '../../components/Selects/SelectCity';
import { dateEua, isDate } from '../../services/date';
import { Divider } from '@mui/material';
import InputDisplay from '../../components/Inputs/InputDisplay';
import { displayMoney } from '../../services/displayValue';
import ModalPlanos from './ModalPlanos';
import moment from 'moment';

export default function CadastroAluno() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_academia_aluno] = useState(infoLogin.id_academia_usuario || 0);
    const [openLoad, setOpenLoad] = useState(false);

    const [id_aluno, setIdAluno] = useState(null);
    const [matricula_aluno, setMatriculaAluno] = useState("");
    const [nome_aluno, setNomeAluno] = useState("");
    const [cpf, setCpf] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [complemento, setComplemento] = useState("");
    const [uf, setUf] = useState(infoLogin.uf || "PA");
    const [cidade, setCidade] = useState(infoLogin.cidade || "");
    const [cep, setCep] = useState(infoLogin.cep || "");
    const [telefone, setTelefone] = useState("");
    const [email, setEmail] = useState("");
    const [data_nascimento, setDataNascimento] = useState("");
    const [adicional, setAdicional] = useState("");
    const [status_aluno, setStatusAluno] = useState(1);
    const [dia_pagamento, setDiaPagamento] = useState(moment().format("DD"));
    const [sexo, setSexo] = useState("M");
    const [id_plano_aluno, setIdPlanoAluno] = useState(null);
    const [nome_plano_aluno, setNomePlanoAluno] = useState("");
    const [valor_plano_aluno, setValorPlanoAluno] = useState("");
    const [openModalPlano, setOpenModalPlano] = useState(false);
    const [next_month, setNextMonth] = useState(false);

    function setDataUser(data) {
        setIdAluno(data.id_aluno);
        setMatriculaAluno(data.matricula_aluno);
        setNomeAluno(data.nome_aluno);
        setCpf(data.cpf);
        setEndereco(data.endereco);
        setNumero(data.numero);
        setBairro(data.bairro);
        setComplemento(data.complemento);
        setUf(data.uf);
        setCidade(data.cidade);
        setCep(data.cep);
        setTelefone(data.telefone);
        setEmail(data.email);
        if (data.data_nascimento) setDataNascimento(dateEua(data.data_nascimento));
        setAdicional(data.adicional);
        setIdPlanoAluno(data.id_plano_aluno);
        setNomePlanoAluno(data.nome_plano_aluno);
        setValorPlanoAluno(data.valor_plano_aluno);
        setStatusAluno(data.status_aluno);
        setDiaPagamento(data.dia_pagamento);
        setSexo(data.sexo);
    }

    async function loadData(cod = null) {
        setOpenLoad(true);
        const id = cod ? cod : searchParms.get('id');
        if (id) {
            try {
                const data = { id_aluno: id };
                const response = await api.post(`/aluno`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setDataUser(response.data[0]);
                }
            } catch (error) {

            }
        }
        setOpenLoad(false);
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            if (isPermission(21) || isPermission(22)) {
                if (id_plano_aluno) {
                    const data = {
                        id_aluno, id_academia_aluno, nome_aluno, cpf, endereco, numero, bairro, complemento,
                        uf, cidade, cep, telefone, email, adicional, matricula_aluno,
                        data_nascimento: isDate(data_nascimento), status_aluno, sexo,
                        id_plano_aluno, nome_plano_aluno, valor_plano_aluno, dia_pagamento, next_month: Number(next_month)
                    };
                    const channel = id_aluno ? `/updatealuno` : `/createaluno`;
                    const response = await api.post(channel, data);
                    let error = 'FALHA NA OPERAÇÃO';
                    if (response && response.status === 200 && response.data) {
                        if (response.data.error) {
                            error = response.data.message;
                        } else {
                            setOpenLoad(false);
                            AlertInfo('success', 'Dados Gravados').then(() => {
                                navigate('/alunos');
                            });
                            return;
                        }
                    }
                    AlertInfo('error', error);
                } else {
                    AlertInfo('info', "SELECIONE UM PLANO");
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm('', "EXCLUIR ALUNO(A)?").then(async res => {
            if (res.isConfirmed) {
                setOpenLoad(true);
                const response = await api.post(`/deletealuno`, { id_aluno });
                if (response && response.status && response.data) {
                    if (response.data.success) {
                        AlertInfo("success", "ALUNO(A) REMOVIDO").then(() => {
                            navigate('/alunos');
                        });
                    } else if (response.data.error) {
                        AlertInfo("error", response.data.message);
                    }
                } else {
                    AlertInfo("error", "Falha na operação");
                }
                setOpenLoad(false);
            }
        });
    }

    async function isExistData(key) {
        if (!id_aluno) {
            let data = null;
            if (key === 'CPF' && cpf.length > 0) data = { cpf, aluno_ativo: 1 };
            else if (key === 'MATRICULA' && matricula_aluno.length > 0) data = { matricula_aluno, aluno_ativo: 1 };

            if (data) {
                const response = await api.post(`/aluno`, data);
                if (response && response.status === 200 && response.data) {
                    if (!response.data.error && response.data[0] && response.data[0].id_aluno) {
                        AlertInfo('info', `${key} JÁ CADASTRADO COMO ALUNO`).then(() => {
                            loadData(response.data[0].id_aluno);
                        });
                    }
                }
            }
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="alunos">
            <br />
            <ModalPlanos
                open={openModalPlano}
                setOpen={setOpenModalPlano}
                setIdPlano={setIdPlanoAluno}
                setNomePlanoAluno={setNomePlanoAluno}
                setValorPlano={setValorPlanoAluno}
            />

            <Paper elevation={3} style={{ padding: 8 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }}>DADOS DO ALUNO</h2>
                    </Grid>


                    <Grid item xs={12} sm={3}>
                        <Input
                            id="matricula_aluno"
                            label="MATRICULA"
                            value={matricula_aluno}
                            setValue={setNomeAluno}
                            next="nome_aluno"
                            onBlur={() => isExistData('MATRICULA')}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Input
                            id="nome_aluno"
                            label="NOME COMPLETO ALUNO(A)"
                            value={nome_aluno}
                            setValue={setNomeAluno}
                            next="cpf"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="cpf"
                            label="CPF"
                            value={cpf}
                            setValue={setCpf}
                            next="endereco"
                            mask="cpf"
                            onBlur={() => isExistData('CPF')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="endereco"
                            label="ENDEREÇO"
                            value={endereco}
                            setValue={setEndereco}
                            next="numero"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="numero"
                            label="NUMERO"
                            value={numero}
                            setValue={setNumero}
                            next="bairro"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="bairro"
                            label="BAIRRO"
                            value={bairro}
                            setValue={setBairro}
                            next="complemento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="complemento"
                            label="COMPLEMENTO"
                            value={complemento}
                            setValue={setComplemento}
                            next="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="UF"
                            value={uf}
                            setValue={setUf}
                            options={arrayUf}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectCity
                            uf={uf}
                            cidade={cidade}
                            setCidade={setCidade}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="cep"
                            label="CEP"
                            value={cep}
                            setValue={setCep}
                            next="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="telefone"
                            label="TELEFONE"
                            value={telefone}
                            setValue={setTelefone}
                            mask="telefone"
                            next="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="email"
                            label="EMAIL"
                            value={email}
                            setValue={setEmail}
                            next="data_nascimento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            type="date"
                            id="data_nascimento"
                            label="DATA DE NASCIMENTO"
                            value={data_nascimento}
                            setValue={setDataNascimento}
                            upper={false}
                            next="nome_mae"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Input
                            id="adicional"
                            label="ADICIONAL"
                            value={adicional}
                            setValue={setAdicional}
                            next="dia_pagamento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="dia_pagamento"
                            label="DIA PAGAMENTO"
                            value={dia_pagamento}
                            setValue={setDiaPagamento}
                            type="number"
                            upper={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="GENERO"
                            value={sexo}
                            setValue={setSexo}
                            options={[
                                { value: 'M', label: 'MASCULINO' },
                                { value: 'F', label: 'FEMININO' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="STATUS ALUNO"
                            value={status_aluno}
                            setValue={setStatusAluno}
                            options={[
                                { value: 1, label: 'ATIVO' },
                                { value: 0, label: 'INATIVO' },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            onClick={() => setOpenModalPlano(true)}
                            fullWidth
                        >
                            <FaFileInvoiceDollar /> &nbsp; PLANO
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {id_plano_aluno && (
                            <InputDisplay
                                label="PLANO"
                                value={nome_plano_aluno}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {id_plano_aluno && (
                            <InputDisplay
                                label="VALOR PLANO"
                                value={`R$ ${displayMoney(valor_plano_aluno)}`}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {!id_aluno && id_plano_aluno && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={next_month}
                                        onChange={() => setNextMonth(!next_month)}
                                    />
                                }
                                label="PARCELA PARA O PROXIMO MÊS"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {id_aluno && isPermission(23) && (
                            <Button
                                variant="contained"
                                color='error'
                                onClick={() => handleDelete()}
                                fullWidth
                            >
                                <FaTrash /> &nbsp; DELETAR
                            </Button>
                        )}
                    </Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}>
                        <Button
                            variant="contained"
                            color='success'
                            onClick={() => handleSave()}
                            fullWidth
                        >
                            SALVAR ALUNO(A)
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>

                </Grid>
            </Paper>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}