import { TextField } from "@mui/material";
import * as allMask from './mask';

export default function Input({ 
    label, value, setValue, upper = true, next = null, mask = null, full = true, clickSelect = false, disabled = false, ...rest 
}) {
    const isMask = (mask && allMask[mask]) ? allMask[mask] : null;
    function handleValue(e) {
        if(disabled){
            //
        }else if(isMask){
            setValue(isMask(e.target.value));
        }else if(upper){
            var ss = e.target.selectionStart;
            var se = e.target.selectionEnd;
            e.target.value = e.target.value.toUpperCase();
            e.target.selectionStart = ss;
            e.target.selectionEnd = se;
            setValue(e.target.value);
        }else{
            setValue(e.target.value);
        }
    }

    function nextInput(e){
        if(next && e.key === 'Enter'){
            let nex = document.getElementById(next);
            if(nex) nex.focus();
        }
    }

    function handleSelect(event) {
        if(clickSelect){
            let inputClick = event.target;
            if(inputClick){
                let inputElement = document.getElementById(inputClick.id);
                if(inputElement) inputElement.select();
            }
        }
    }

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth={full}
            value={value}
            size="small"
            onChange={handleValue}
            onKeyUp={nextInput}
            {...rest}
            onClick={handleSelect}
        />
    );
}