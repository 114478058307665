import { Grid } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import TableSimple from "../../components/Tables/TableSimple";
import { useEffect, useState } from "react";
import { displayMoney } from "../../services/displayValue";
import { api } from "../../services/api";

export default function ModalPlanos({ open, setOpen, setIdPlano, setValorPlano, setNomePlanoAluno }) {
    const [planos, setPlanos] = useState([]);
    const columnsPlanos = [
        {
            id: 'plano',
            numeric: false,
            label: 'PLANO',
        },
        {
            id: 'valor_plano',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        }
    ];

    function handleSelectPlano(plano) {
        setIdPlano(plano.id_plano);
        setValorPlano(plano.valor_plano);
        setNomePlanoAluno(plano.plano);
        setOpen(false);
    }

    async function loadPlanos() {
        try {
            const response = await api.post(`/planos`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) setPlanos(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadPlanos();
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="SELECIONAR PLANO"
            maxWidth='sm'
        >
            <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                    {openLoad && (
                        <Box sx={{ width: '100%' }}>
                            <br />
                            <LinearProgress />
                        </Box>
                    )}
                </Grid> */}
                <Grid item xs={12}>
                    <TableSimple
                        rows={planos}
                        columns={columnsPlanos}
                        clickFunc={handleSelectPlano}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}