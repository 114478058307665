import { useEffect, useState } from "react";
import SelectData from "./SelectData";
import { api } from "../../services/api";

export default function SelectCity({ cidade, setCidade, uf }) {
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(String(cidade).length > 0 ? {label: cidade} : "");

    async function loadCitys() {
        if(uf && String(uf).length === 2){
            try {
                const response = await api.get(`/cidades/${uf}`);
                if(response && response.status === 200 && response.data){
                    if(!response.data.error){
                        setOptions(response.data);
                        return;
                    }
                }                
            } catch (error) {
                console.log(error);
            }
        }
        setOptions([]);
    }

    function isSelect() {
        if(value && value.label && value.label !== cidade){
            setCidade(value.label);
        }
    }

    useEffect(() => {
        isSelect();
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        loadCitys();
        // eslint-disable-next-line
    }, [uf]);

    return(
        <SelectData
            label="CIDADE"
            value={value}
            setValue={setValue}
            options={options}
        />
    );
}