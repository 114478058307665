import { useState, useEffect } from "react";
import { FaMoneyBill, FaPix, FaCreditCard, FaFilePen, FaTrash, FaUser } from "react-icons/fa6";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Inputs/Input";
import CardButtonMini from "../../components/CardButton/CardButtonMini";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo, AlertOptionsParcelas } from "../../components/Alerts/Alert";
import { converterMoeda, displayMoney } from "../../services/displayValue";
import { api, apiUrl } from "../../services/api";

import ModalSearchAlunos from "../Alunos/ModalSearchAlunos";

export default function ModalFinalizarVenda({ open, setOpen, subtotal, produtos, reset }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin') || ''));
    const [openLoad, setOpenLoad] = useState(false);
    const [restante, setRestante] = useState(0);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [typeEditValue, setTypeEditValue] = useState("R$");
    const [inputDesconto, setInputDesconto] = useState("");
    const [inputAcrescimo, setInputAcrescimo] = useState("");
    const [desconto, setDesconto] = useState(0);
    const [acrescimo, setAcrescimo] = useState(0);
    const [dinheiro, setDinheiro] = useState(0);
    const [aprazo, setAprazo] = useState(0);
    const [debito, setDebito] = useState(0);
    const [credito, setCredito] = useState(0);
    const [pix, setPix] = useState(0);
    const [parcelas, setParcelas] = useState(0);
    const [impress, setImpress] = useState(false);
    const [valor_pago, setValorPago] = useState("");
    const [openModalAluno, setOpenModalAluno] = useState(false);
    const [id_aluno_venda, setIdAlunoVenda] = useState(0);
    const [nome_aluno, setNomeAluno] = useState("");

    function handlePay(setMethod, isTroco = false, isParcel = false) {
        const vlr = converterMoeda(valor_pago);
        if (vlr > 0) {
            if (isParcel) {
                AlertOptionsParcelas().then(res => {
                    if (res.isConfirmed) {
                        if (Number(res.value) > 0) {
                            setParcelas(res.value);
                            setMethod(vlr);
                        } else {
                            AlertInfo('info', 'QUANTIDADE DE PARCELAS INVALIDA');
                        }
                    }
                });
            } else if (!isTroco && vlr > restante) {
                AlertInfo('info', 'TROCO NÃO DISPONIVEL PARA ESTA OPÇÃO');
            } else {
                setMethod(vlr);
            }
        }
    }

    function selectValuePago() {
        let valorPagoInput = document.getElementById('valor_pago');
        if (valorPagoInput) valorPagoInput.select();
    }

    function handleRestante() {
        if (restante > 0) setOpenModalEdit(true);
    }

    function handleDescontoAcrescimo() {
        if (typeEditValue === 'R$') {
            setDesconto(converterMoeda(inputDesconto));
            setAcrescimo(converterMoeda(inputAcrescimo));
        } else if (typeEditValue === '%') {
            const valueDesconto = converterMoeda(displayMoney((subtotal / 100) * converterMoeda(inputDesconto)));
            const valueAcrescimo = converterMoeda(displayMoney((subtotal / 100) * converterMoeda(inputAcrescimo)));
            setAcrescimo(valueAcrescimo);
            setDesconto(valueDesconto);
            setTypeEditValue("R$");
            setInputDesconto(displayMoney(valueDesconto));
            setInputAcrescimo(displayMoney(valueAcrescimo));
        }
        setOpenModalEdit(false);
    }

    function clearModal() {
        setRestante(0);
        setInputDesconto("");
        setInputAcrescimo("");
        setDesconto(0);
        setAcrescimo(0);
        setDinheiro(0);
        setAprazo(0);
        setDebito(0);
        setCredito(0);
        setPix(0);
        setParcelas(0);
        setIdAlunoVenda(0);
        setValorPago("");
        setOpen(false);
        reset();
    }

    function openReciboVenda(id_venda) {
        const url = `${apiUrl}/recibovenda/${id_venda}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: url})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(url);
        }
    }

    async function handleFinalizar() {
        if (aprazo > 0 && id_aluno_venda === 0) {
            AlertInfo('info', 'SELECIONE O COMPRADOR PARA VENDA A PRAZO');
        } else {
            setOpenLoad(true);
            try {
                const data = {
                    venda: {
                        id_academia_venda: infoLogin.id_academia_usuario,
                        id_usuario_venda: infoLogin.id_usuario,
                        subtotal, desconto, acrescimo, id_aluno_venda,
                        total: subtotal - desconto + acrescimo,
                        dinheiro, pix, credito, debito, aprazo, parcelas,
                        troco: restante < 0 ? Math.abs(restante) : 0
                    },
                    produtos
                };
                const response = await api.post(`/addvenda`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) {
                        AlertInfo('error', response.data.message);
                    } else if (response.data.success) {
                        AlertInfo('success', 'VENDA EFETUADA').then(() => {
                            if (impress) openReciboVenda(response.data.id_venda);
                        });
                        clearModal();
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA CONEXÃO');
            }
            setOpenLoad(false);
        }
    }

    function DisplayMethodsPayment({ text, value, setValue, isText = false }) {
        return (
            <>
                <Grid item xs={12} container justifyContent="space-between">
                    <h3 style={{ fontSize: 14, textAlign: 'right' }}>{text}:</h3>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <h3 style={{ fontSize: 14 }}>{isText ? value : displayMoney(value)}</h3>
                        <FaTrash style={{ cursor: 'pointer' }} onClick={() => setValue(0)} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
            </>
        );
    }

    function selectComprador(cli) {
        setIdAlunoVenda(cli.id_aluno);
        setNomeAluno(cli.nome_aluno);
        setOpenModalAluno(false);
    }

    useEffect(() => {
        const res = subtotal - dinheiro - pix - credito - debito - aprazo - desconto + acrescimo;
        setRestante(res);
        setValorPago(displayMoney(res));
    }, [dinheiro, pix, credito, debito, aprazo, desconto, acrescimo, subtotal]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="FINALIZAR VENDA"
            maxWidth="xs"
        >
            <SimpleBackdrop open={openLoad} />
            <ModalSearchAlunos
                open={openModalAluno}
                setOpen={setOpenModalAluno}
                select={selectComprador}
            />

            <Modal
                open={openModalEdit}
                setOpen={setOpenModalEdit}
                title="AJUSTAR VALOR"
                maxWidth="xs"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            value={typeEditValue}
                            onChange={e => setTypeEditValue(e.target.value)}
                        >
                            <FormControlLabel value="R$" control={<Radio />} label="R$" />
                            <FormControlLabel value="%" control={<Radio />} label="%" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="desconto"
                            label="DESCONTO"
                            value={inputDesconto}
                            setValue={setInputDesconto}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="acrescimo"
                            label="ACRESCIMO"
                            value={inputAcrescimo}
                            setValue={setInputAcrescimo}
                        />
                    </Grid>
                    <Grid item xs={false} sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleDescontoAcrescimo}
                        >
                            APLICAR
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={2}></Grid>
                </Grid>
            </Modal>

            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <h3 style={{ fontSize: 14 }}>{restante > 0 ? 'RESTANTE' : 'TROCO'}</h3>
                    <h2 style={{ fontSize: 32, cursor: 'pointer' }} onClick={handleRestante}>{displayMoney(Math.abs(restante))}</h2>
                </Grid>

                {id_aluno_venda > 0 && (
                    <DisplayMethodsPayment text="COMPRADOR" value={nome_aluno} setValue={setIdAlunoVenda} isText />
                )}

                {dinheiro > 0 && (
                    <DisplayMethodsPayment text="DINHEIRO" value={dinheiro} setValue={setDinheiro} />
                )}
                {pix > 0 && (
                    <DisplayMethodsPayment text="PIX" value={pix} setValue={setPix} />
                )}
                {credito > 0 && (
                    <DisplayMethodsPayment text="CREDITO" value={credito} setValue={setCredito} />
                )}
                {debito > 0 && (
                    <DisplayMethodsPayment text="DEBITO" value={debito} setValue={setDebito} />
                )}
                {aprazo > 0 && (
                    <DisplayMethodsPayment text="A PRAZO" value={aprazo} setValue={setAprazo} />
                )}

                <Grid item xs={false} sm={6}></Grid>
                {Number(id_aluno_venda) === 0 && (
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="secondary"
                            onClick={() => setOpenModalAluno(true)}
                        >
                            <FaUser /> &nbsp; COMPRADOR
                        </Button>
                    </Grid>
                )}

                {restante > 0 && (
                    <>
                        <Grid item xs={12}>
                            <Input
                                id="valor_pago"
                                label="VALOR PAGO"
                                value={valor_pago}
                                setValue={setValorPago}
                                onClick={selectValuePago}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardButtonMini
                                title="DINHEIRO"
                                icon={FaMoneyBill}
                                onClick={() => handlePay(setDinheiro, true)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardButtonMini
                                title="PIX"
                                icon={FaPix}
                                onClick={() => handlePay(setPix)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardButtonMini
                                title="CREDITO"
                                icon={FaCreditCard}
                                onClick={() => handlePay(setCredito, false, true)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardButtonMini
                                title="DEBITO"
                                icon={FaCreditCard}
                                onClick={() => handlePay(setDebito)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CardButtonMini
                                title="A PRAZO"
                                icon={FaFilePen}
                                onClick={() => handlePay(setAprazo, false, true)}
                            />
                        </Grid>
                    </>
                )}

                {restante <= 0 && (
                    <>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={impress} onChange={() => setImpress(!impress)} />
                                }
                                label="ABRIR COMPROVANTE"
                            />
                        </Grid>
                        <Grid item xs={false} sm={2}></Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="success"
                                onClick={handleFinalizar}
                            >
                                FINALIZAR
                            </Button>
                        </Grid>
                        <Grid item xs={false} sm={2}></Grid>
                    </>
                )}
            </Grid>
        </Modal>
    );
}