import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';

import DrawerFiltroAlunos from './DrawerFilterAlunos';

export default function Alunos() {
    const [openLoad, setOpenLoad] = useState(false);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtro, setFiltro] = useState("");
    const [dataAluno, setDataAluno] = useState("");
    const [openFilters, setOpenFilters] = useState(false);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'matricula_aluno',
            numeric: false,
            label: 'MATRICULA',
        },
        {
            id: 'nome_aluno',
            numeric: false,
            disablePadding: false,
            label: 'NOME ALUNO',
            minWidth: '300px'
        },
        {
            id: 'status_aluno',
            numeric: false,
            disablePadding: false,
            label: 'STATUS',
            format: (cod) => Number(cod) === 1 ? 'ATIVO' : 'INATIVO'
        },
        {
            id: 'telefone',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE',
            minWidth: '160px'
        }
    ];

    function handleClick(row) {
        if(isPermission(22) && row.id_aluno){
            navigate(`/cadastroaluno?id=${row.id_aluno}`);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/alunos`);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo("error", response.data.message);
                }else{
                    setAllRows(response.data);
                    setRows(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    function handleFilter() {
        if(filtro.length === 0){
            setRows(allRows);
        }else{
            const rowsFilter = allRows.filter(al => String(al.nome_aluno).includes(filtro));
            setRows(rowsFilter);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return(
        <Template menuActive="alunos">
            <DrawerFiltroAlunos
                open={openFilters}
                setOpen={setOpenFilters}
                setText={setDataAluno}
                setRows={setRows}
                setAllRows={setAllRows}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(20) && (
                        <Link to="/cadastroaluno">
                            <Button 
                                variant="contained"
                                fullWidth
                            >
                                NOVO ALUNO(A)
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        label="BUSCAR"
                        value={filtro}
                        setValue={setFiltro}
                        onKeyUp={e => {
                            if(e.key === 'Enter') handleFilter();
                        }}
                    />
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={3} justifyContent="flex-end" alignItems="center" container sx={{ gap: 1 }}>
                    {String(dataAluno).length > 0 && (
                        <Chip label={dataAluno} color="primary" />
                    )}
                    <Fab size="small" color="primary" onClick={() => setOpenFilters(true)} sx={{ zIndex: 1 }}>
                        <SearchIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12}>
                    <h3>Lista de Alunos</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        label="Lista de Alunos"
                        rows={rows}
                        columns={columns} 
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}