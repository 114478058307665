import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Link Academia
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function SignIn() {
    const [searchParms] = useSearchParams();
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [openLoad, setOpenLoad] = useState(false);
    const navigate = useNavigate();

    async function doLogin(data) {
        setOpenLoad(true);
        try {
            const response = await api.post(`/login`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                    if (window && window.ReactNativeWebView) {
                        const dataPost = JSON.stringify({ error: true, message: response.data.message });
                        window.ReactNativeWebView.postMessage(dataPost);
                    }
                } else {
                    sessionStorage.setItem(`infoLogin`, JSON.stringify(response.data));
                    navigate(`/`);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
            if (window && window.ReactNativeWebView) {
                const dataPost = JSON.stringify({ error: true, message: 'FALHA NA CONEXÃO' });
                window.ReactNativeWebView.postMessage(dataPost);
            }
        }
        setOpenLoad(false);
    }

    function nextInput(event) {
        if (event.key === 'Enter') {
            let passInput = document.getElementById('password');
            if (passInput) passInput.focus();
        }
    }

    function exeLogin(event) {
        if (event.key === 'Enter') doLogin({ user, pass });
    }

    async function loadData() {
        const isUser = searchParms.get('isUser');
        const isPass = searchParms.get('isPass');
        if (isUser && isPass) doLogin({ user: isUser, pass: isPass });
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <SimpleBackdrop open={openLoad} />
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    ENTRAR NO SISTEMA
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email, Telefone ou CPF Usuario"
                        name="email"
                        value={user}
                        onChange={e => setUser(e.target.value)}
                        onKeyUp={nextInput}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={pass}
                        onChange={e => setPass(e.target.value)}
                        onKeyUp={exeLogin}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => doLogin({ user, pass })}
                    >
                        ENTRAR
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}