import { useState } from "react";
import { Divider, Grid, Button, FormControlLabel, Checkbox } from "@mui/material";
import InputDisplay from "../../components/Inputs/InputDisplay";
import Modal from "../../components/Modal/Modal";
import LabelAsaas from "../../components/Labels/LabelAsaas";
import { displayMoney } from "../../services/displayValue";
import { dateBrl, dateEua, getHors, isDate } from "../../services/date";
import { api, apiUrl } from "../../services/api";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import moment from "moment";
import Input from "../../components/Inputs/Input";
import SelectSimple from "../../components/Selects/SelectSimple";

export default function ModalInfoParcela({ open, setOpen, info, setInfo, loadData }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [permissoes_usuario] = useState(String(infoLogin.permissoes_usuario).split(','));
    const [openModalReceber, setOpenModalReceber] = useState(false);
    const [pagamento, setPagamento] = useState(dateEua());
    const [forma_pagamento, setFormaPagamento] = useState("DINHEIRO");
    const [is_recibo, setIsRecibo] = useState(false);

    function isPermite(cod = 0) {
        const permite = permissoes_usuario.filter(p => Number(p) === Number(cod));
        if(permite.length === 1) return true;
        AlertInfo('error', 'USUARIO SEM PERMISSÃO');
        return false;
    }

    async function handleReceber() {
        if(isPermite(61)){
            if(isDate(pagamento)){
                const dataParcela = {
                    id_parcela: info.id_parcela,
                    id_aluno_parcela: info.id_aluno_parcela,
                    vencimento: info.vencimento,
                    data: {
                        pagamento: `${pagamento} ${getHors()}`,
                        usuario_recebedor: infoLogin.nome_usuario,
                        situacao: 'RECEIVED',
                        forma_pagamento,
                    }
                };
                const response = await api.post(`/receberparcela`, dataParcela);
                if (response && response.status === 200 && response.data) {
                    if (response.data.success) {
                        AlertInfo('success', 'MENSALIDADE RECEBIDA');
                        setInfo({...info, ...dataParcela.data});
                        setOpenModalReceber(false);
                        loadData();
                    }
                }
            }else{
                AlertInfo('error', 'SELECIONE UMA DATA VALIDA');
            }
        }
    }

    async function cancelParcela() {
        if(isPermite(62)){
            AlertConfirm("", "MARCAR PARCELA COMO CANCELADA?", "", "SIM").then(async res => {
                if(res.isConfirmed){
                    const dataParcela = {
                        id_parcela: info.id_parcela,
                        data: {
                            usuario_recebedor: infoLogin.nome_usuario,
                            situacao: 'CANCELED',
                        }
                    };
                    const response = await api.post(`/updateparcela`, dataParcela);
                    if (response && response.status === 200 && response.data) {
                        if (response.data.success) {
                            AlertInfo('success', 'PARCELA CANCELADA');
                            setInfo({...info, ...dataParcela.data});
                            setOpenModalReceber(false);
                            loadData();
                        }
                    }
                }
            });
        }
    }
    
    async function reactiveParcela() {
        if(isPermite(64)){
            AlertConfirm("", "REATIVAR PARCELA?", "", "SIM").then(async res => {
                if(res.isConfirmed){
                    const dataParcela = {
                        id_parcela: info.id_parcela,
                        data: {
                            usuario_recebedor: infoLogin.nome_usuario,
                            situacao: moment().isAfter(moment(info.vencimento)) ? 'OVERDUE' : 'PENDING',
                        }
                    };
                    const response = await api.post(`/updateparcela`, dataParcela);
                    if (response && response.status === 200 && response.data) {
                        if (response.data.success) {
                            AlertInfo('success', 'PARCELA REATIVADA');
                            setInfo({...info, ...dataParcela.data});
                            setOpenModalReceber(false);
                            loadData();
                        }
                    }
                }
            });
        }
    }
    
    async function extornarParcela() {
        if(isPermite(63)){
            AlertConfirm("", "EXTORNAR PARCELA?", "", "SIM").then(async res => {
                if(res.isConfirmed){
                    const dataParcela = {
                        id_parcela: info.id_parcela,
                        data: {
                            usuario_recebedor: infoLogin.nome_usuario,
                            situacao: moment().isAfter(moment(info.vencimento)) ? 'OVERDUE' : 'PENDING',
                            pagamento: null,
                            forma_pagamento: ''
                        }
                    };
                    const response = await api.post(`/updateparcela`, dataParcela);
                    if (response && response.status === 200 && response.data) {
                        if (response.data.success) {
                            AlertInfo('success', 'PARCELA EXTORNADA');
                            setInfo({...info, ...dataParcela.data});
                            setOpenModalReceber(false);
                            loadData();
                        }
                    }
                }
            });
        }
    }

    function openRecibo() {
        const url = `${apiUrl}/reciboparcela/${info.id_parcela}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: url})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(url);
        }
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={`MENSALIDADE`}
            maxWidth="md"
        >
            <Modal
                open={openModalReceber}
                setOpen={setOpenModalReceber}
                title={`MENSALIDADE`}
                maxWidth="xs"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Input
                            type="date"
                            value={pagamento}
                            setValue={setPagamento}
                            upper={false}
                            label="DATA PAGAMENTO"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectSimple
                            label="FORMA DE PAGAMENTO"
                            value={forma_pagamento}
                            setValue={setFormaPagamento}
                            options={[
                                { value: 'DINHEIRO', label: 'DINHEIRO' },
                                { value: 'CARTÃO CREDITO', label: 'CARTÃO CREDITO' },
                                { value: 'CARTÃO DEBITO', label: 'CARTÃO DEBITO' },
                                { value: 'PIX', label: 'PIX' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel 
                            control={
                                <Checkbox checked={is_recibo} onChange={() => setIsRecibo(!is_recibo)} />
                            } 
                            label="GERAR RECIBO" 
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={() => handleReceber()}
                        >
                            RECEBER PARCELA
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
            {info && info.id_parcela && (
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} container spacing={0}>
                        <Grid item xs={12}>
                            <InputDisplay
                                label="NOME COMPLETO ALUNO(A)"
                                value={info.nome_aluno}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="MATRICULA"
                                value={info.matricula_aluno}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="TELEFONE"
                                value={info.telefone}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} container spacing={0}>
                        <Grid item xs={12}>
                            <InputDisplay
                                label="APELIDO"
                                value={info.apelido}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputDisplay
                                label="STATUS"
                                value={Number(info.status_aluno) === 1 ? 'ATIVO' : 'INATIVO'}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ marginBottom: 3 }} />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <InputDisplay
                            label="VALOR PARCELA"
                            value={`R$ ${displayMoney(info.valor_parcela)}`}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InputDisplay
                            label="VENCIMENTO"
                            value={dateBrl(info.vencimento)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InputDisplay
                            label="DATA PAGAMENTO"
                            value={info.pagamento ? dateBrl(info.pagamento) : '-------'}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <LabelAsaas item={info.situacao} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InputDisplay
                            label="USUARIO"
                            value={String(info.usuario_recebedor).length > 0 ? info.usuario_recebedor : '-------'}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <InputDisplay
                            label="FORMA PAGAMENRTO"
                            value={String(info.forma_pagamento).length > 0 ? info.forma_pagamento : '-------'}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ marginBottom: 3 }} />
                    </Grid>

                    <Grid item xs={12} container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            {(info.situacao === 'PENDING' || info.situacao === 'OVERDUE') && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="success"
                                    onClick={() => setOpenModalReceber(true)}
                                >
                                    RECEBER PARCELA
                                </Button>
                            )}
                            {info.situacao === 'RECEIVED' && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="warning"
                                    onClick={() => extornarParcela()}
                                >
                                    EXTORNAR PARCELA
                                </Button>
                            )}
                            {info.situacao === 'CANCELED' && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="warning"
                                    onClick={() => reactiveParcela()}
                                >
                                    REATIVAR PARCELA
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={false} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                            {(info.situacao === 'PENDING' || info.situacao === 'OVERDUE') && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="error"
                                    onClick={() => cancelParcela()}
                                >
                                    CANCELAR PARCELA
                                </Button>
                            )}
                            {info.situacao === 'RECEIVED' && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => openRecibo()}
                                >
                                    GERAR RECIBO
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                </Grid>
            )}
        </Modal>
    );
}