import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ListActions from "../../components/Lists/ListActions";
import Modal from "../../components/Modal/Modal";
import Input from '../../components/Inputs/Input';
import { api } from "../../services/api";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";

export default function ModalCategorias({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_academia_categoria] = useState(infoLogin.id_academia_usuario || 0);
    const [categorias, setCategorias] = useState([]);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [id_categoria, setIdCategoria] = useState(null);
    const [nome_categoria, setNomeCategoria] = useState("");

    function deleteCategoria(item) {
        AlertConfirm('', 'REMOVER PLANO?').then(async res => {
            if(res.isConfirmed){
                const { id_categoria = 0 } = item;
                const response = await api.delete(`/deletecategoria/${id_categoria}`);
                if(response && response.status === 200 && response.data){
                    if(response.data.success) AlertInfo('success', 'CATEGORIA REMOVIDA');
                    else AlertInfo('error', response.data.message);
                }
                loadCategorias();
            }
        });
    }

    async function loadCategorias() {
        try {
            const response = await api.get(`/categorias`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo('error', response.data.message);
                else setCategorias(response.data);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
    }

    function editCategoria(item) {
        setIdCategoria(item.id_categoria);
        setNomeCategoria(item.nome_categoria);
        setOpenModalAdd(true);
    }

    async function addCategoria() {
        const data = { id_academia_categoria, nome_categoria };
        const channel = id_categoria ? 'updatecategoria' : 'createcategoria';
        const dataPlano = id_categoria ? { id_categoria, data } : data;
        const response = await api.post(channel, dataPlano);
        if(response && response.status === 200 && response.data){
            if(response.data.error){
                AlertInfo('error', response.data.message);
            }else{
                setOpenModalAdd(false);
                setIdCategoria(null);
                setNomeCategoria("");
                loadCategorias();
            }
        }
    }

    useEffect(() => {
        loadCategorias();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="CATEGORIAS"
            maxWidth="sm"
        >
            <Modal 
                open={openModalAdd} 
                setOpen={setOpenModalAdd} 
                title="ADICIONAR CATEGORIA"
            >
                <Input
                    id="nome_categoria"
                    label="NOME CATEGORIA"
                    value={nome_categoria}
                    setValue={setNomeCategoria}
                />
                
                <br/><br/>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <Button
                        variant="contained"
                        color='success'
                        onClick={() => addCategoria()}
                    >
                        ADICIONAR
                    </Button>
                </div>
            </Modal>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="contained"
                    color='success'
                    onClick={() => setOpenModalAdd(true)}
                >
                    ADICIONAR
                </Button>
            </div>
            <ListActions
                array={categorias}
                keyItem="nome_categoria"
                emptyText='NENHUMA CATEGORIA CADASTRADA'
                deleteFunc={deleteCategoria}
                editFunc={editCategoria}
            />
        </Modal>
    );
}