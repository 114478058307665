import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { displayId, displayMoney } from '../../services/displayValue';

export default function Produtos() {
    const [openLoad, setOpenLoad] = useState(false);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtro, setFiltro] = useState("");
    const navigate = useNavigate();
    const columns = [
        {
            id: 'id_produto',
            numeric: false,
            label: 'CODIGO',
            format: displayId,
        },
        {
            id: 'descricao_produto',
            numeric: false,
            disablePadding: false,
            label: 'DESCRIÇÃO',
            minWidth: '300px'
        },
        {
            id: 'quantidade_produto',
            numeric: true,
            disablePadding: false,
            label: 'QUANTIDADE',
        },
        {
            id: 'preco_produto',
            numeric: true,
            disablePadding: false,
            label: 'PREÇO',
            format: displayMoney
        }
    ];

    function handleClick(row) {
        if(isPermission(32) && row.id_produto){
            navigate(`/cadastroproduto?id=${row.id_produto}`);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/produtos`);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo("error", response.data.message);
                }else{
                    setAllRows(response.data);
                    setRows(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    function handleFilter() {
        if (filtro.length === 0) {
            setRows(allRows);
        } else {
            const rowsFilter = allRows.filter(al => String(al.descricao_produto).includes(filtro));
            setRows(rowsFilter);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="produtos">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(31) && (
                        <Link to="/cadastroproduto">
                            <Button
                                variant="contained"
                                fullWidth
                            >
                                NOVO PRODUTO
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={false} sm={7}></Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        label="BUSCAR"
                        value={filtro}
                        setValue={setFiltro}
                        onKeyUp={e => {
                            if (e.key === 'Enter') handleFilter();
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Lista de Produtos</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        label="Lista de Produtos"
                        rows={rows}
                        columns={columns}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}