import { useState } from "react";
import moment from "moment";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from '../../components/Inputs/Input';
import Modal from "../../components/Modal/Modal";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { AlertInfo } from "../../components/Alerts/Alert";
import { startMes, endMes, dateEua, dateBrlExat } from "../../services/date";
import { api } from "../../services/api";
import { displayId, displayMoney } from "../../services/displayValue";

import ModalInfoVenda from "./ModalInfoVenda";

export default function DrawerFilterVenda({ open, setOpen }) {
    const [openLoad, setOpenLoad] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [infoModal, setInfoModal] = useState(0);
    const [rows, setRows] = useState([]);
    const [busca, setBusca] = useState("3");
    const [mes, setMes] = useState(moment().format('YYYY-MM'));
    const [dataInicio, setDataInicio] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [dataUnica, setDataUnica] = useState(dateEua());
    const [id_venda, setIdVenda] = useState("");
    const [nome_aluno, setNomeAluno] = useState("");
    const columns = [
        {
            id: 'id_venda',
            numeric: false,
            label: 'CODIGO',
            format: displayId,
        },
        {
            id: 'nome_aluno',
            numeric: false,
            label: 'COMPRADOR',
            format: (txt) => txt && String(txt).length > 0 ? txt : "VENDA AVULSA",
            minWidth: '300px'
        },
        {
            id: 'data_venda',
            numeric: false,
            label: 'DATA',
            format: dateBrlExat,
            minWidth: '180px'
        },
        {
            id: 'total',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        }
    ];

    async function handleSearch() {
        let channel = `/getvendasdata`;
        let start = `${dataUnica} 00:00:00`;
        let end = `${dataUnica} 23:59:59`;
        let data = { start, end };
        if (busca === "1") {
            start = startMes(mes, 'datetime');
            end = endMes(mes, 'datetime');
            data = { start, end };
        } else if (busca === "2") {
            start = `${dataInicio} 00:00:00`;
            end = `${dataFinal} 23:59:59`;
            data = { start, end };
        } else if (busca === "4") {
            channel = `/getvenda`;
            data = { id_venda };
        } else if (busca === "5") {
            channel = `/getvendasbusca`;
            data = { nome_aluno };
        }
        setRows([]);
        setOpenLoad(true);
        try {
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    setRows(response.data);
                    setOpenModal(true);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function handleClickVenda(item) {
        setInfoModal(item.id_venda);
        setOpenModalInfo(true);
    }

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <SimpleBackdrop open={openLoad} />
            <Modal
                open={openModal}
                setOpen={setOpenModal}
                title="VENDAS"
                maxWidth="md"
                // zIndex={5555}
            >
                <TableDataSimple
                    columns={columns}
                    rows={rows}
                    handleClick={handleClickVenda}
                />
            </Modal>
            <ModalInfoVenda
                open={openModalInfo}
                setOpen={setOpenModalInfo}
                id_venda={infoModal}
                reset={handleSearch}
            />

            <ListItem>
                <SelectSimple
                    label="BUSCA"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "1", label: 'MÊS' },
                        { value: "2", label: 'PERIODO' },
                        { value: "3", label: 'DIA' },
                        { value: "4", label: 'CODIGO VENDA' },
                        { value: "5", label: 'COMPRADOR' },
                    ]}
                />
            </ListItem>

            {busca === "1" && (
                <ListItem>
                    <Input
                        type="month"
                        label="MÊS"
                        value={mes}
                        upper={false}
                        setValue={setMes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}
            {busca === "2" && (
                <>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA INCIO"
                            value={dataInicio}
                            setValue={setDataInicio}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA FINAL"
                            value={dataFinal}
                            upper={false}
                            setValue={setDataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                </>
            )}

            {busca === "3" && (
                <ListItem>
                    <Input
                        type="date"
                        label="DATA"
                        value={dataUnica}
                        setValue={setDataUnica}
                        upper={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}

            {busca === "4" && (
                <ListItem>
                    <Input
                        type="number"
                        label="CODIGO DA VENDA"
                        value={id_venda}
                        setValue={setIdVenda}
                        upper={false}
                    />
                </ListItem>
            )}

            {busca === "5" && (
                <ListItem>
                    <Input
                        label="NOME COMPRADOR"
                        value={nome_aluno}
                        setValue={setNomeAluno}
                    />
                </ListItem>
            )}

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    FILTRAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}