const isPermission = (cod) => {
    const infoLogin = JSON.parse(sessionStorage.getItem('infoLogin'));
    if(infoLogin && infoLogin.permissoes_usuario){
        const permissoes_usuario = String(infoLogin.permissoes_usuario).split(',');
        const permite = permissoes_usuario.filter(p => Number(p) === Number(cod));
        if(permite.length === 1) return true;
    }
    return false;
}

export default isPermission;