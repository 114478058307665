import Paper from "@mui/material/Paper";

export default function CardButtonMini({ title, icon: Icon, ...rest }) {
    return (
        <Paper 
            elevation={6} 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                cursor: 'pointer', 
                gap: 2, 
                padding: 1, 
                ':hover': { backgroundColor: '#ddd' } }
            }
            {...rest}
        >
            <Icon size={22} />
            <span>{title}</span>
        </Paper>
    );
}