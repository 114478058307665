export const converterMoeda = (value) => {
    if(value === ''){
		return 0;
	}else{
        var val = String(value);
		var real = val.replace(".","");
        var real2 = real.replace(".","");
		var cents = real2.replace(",",".");
        return Number(cents);
	}
}

export const displayMoney = (value) => {
    if(value || Number(value) === 0){
        const format = Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
        }).format(value);
        return format;
    }else{
        return '';
    }
}

export const displayPercent = (value) => {
    if(value || Number(value) > 0){
        const format = Intl.NumberFormat('pt-br', {
            maximumFractionDigits: 2
        }).format(value);
        return format+"%";
    }else{
        return '';
    }
}

export const displayQuantidade = (value) => {
    if(value || Number(value) === 0){
        const format = Intl.NumberFormat('pt-br', {
            maximumFractionDigits: 3
        }).format(value);
        return format;
    }else{
        return '';
    }
}

export const displayId = (id, repeat = 6) => {
    if(id && id.length > 6){
        return id;
    }else if(!id){
        return "";
    }else{
        return String(id).padStart(repeat, "0");
        // return ("000000" + id).slice(-6);
    }
}

export const displayNumber2 = (id) => ("00" + id).slice(-2);
export const displayNumber3 = (id) => ("000" + id).slice(-3);

export const removeMask = (mask) => {
    let regra = /[^a-z0-9]/gi;
    const value = mask.replace(regra, "");
    return value;
}

export const displayNumberRandon = (quant) => {
    const randon = String(Math.random());
    const resp = randon.substr(2, quant);
    return resp;
}