import { useState, useEffect } from "react";
import { FaShoppingBasket, FaTrash, FaSearch } from "react-icons/fa";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Template from "../../template/Template";
import Input from "../../components/Inputs/Input";
import CardTotal from "../../components/Cards/CardTotal";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import isPermission from "../../services/permission";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { displayMoney, displayNumber3 } from "../../services/displayValue";
import { api } from "../../services/api";

import ModalListaProduto from "./ModalListaProdutos";
import ModalFinalizarVenda from "./ModalFinalizarVenda";
import DrawerFilterVenda from "./DrawerFilterVenda";

export default function Venda() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin') || ''));
    const [openLoad, setOpenLoad] = useState(false);
    const [busca_produto, setBuscaProduto] = useState("");
    const [quantidade_saida, setQuantidadeSaida] = useState("");
    const [atualProd, setAtualProd] = useState(null);
    const [arrayProds, setArrayProds] = useState([]);
    const [openModalProds, setOpenModalProds] = useState(false);
    const [openModalFinalizar, setOpenModalFinalizar] = useState(false);
    const [produtosVenda, setProdutosVenda] = useState([]);
    const [total_venda, setTotalVenda] = useState(0);
    const [openFilter, setOpenFilter] = useState(false);

    function clearInputs() {
        setBuscaProduto("");
        setQuantidadeSaida("");
        setAtualProd(null);
    }

    function reset() {
        clearInputs();
        setProdutosVenda([]);
    }

    function handleReset() {
        if (produtosVenda.length > 0) {
            AlertConfirm('', 'LIMPAR VENDA ATUAL?', '', 'SIM').then(res => {
                if (res.isConfirmed) reset();
            });
        }
    }

    function addItem(prod, quant) {
        const item = { ...prod, quantidade_saida: quant, total: Number(quant) * prod.preco_produto };
        setProdutosVenda([...produtosVenda, item]);
        clearInputs();
    }

    function listProds(array) {
        if (array.length === 0) {
            AlertInfo('info', "Nenhum produto localizado!");
        } else if (array.length === 1) {
            handleSelectProd(array[0]);
        } else if (array.length > 1) {
            setArrayProds(array);
            setOpenModalProds(true);
        }
    }

    async function searchProd(event) {
        setOpenLoad(true);
        try {
            const data = { id_academia_produto: infoLogin.id_academia_usuario, search: busca_produto };
            const response = await api.post(`searchproduto`, data);
            console.log(response);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else listProds(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    function handleProd(event) {
        if (event.key === 'Enter') {
            if (atualProd && atualProd.id_produto) {
                const quant = Number(quantidade_saida) <= 0 ? 1 : Number(quantidade_saida);
                addItem(atualProd, quant);
            }
            let buscaProdutoInput = document.getElementById('busca_produto');
            if (buscaProdutoInput) buscaProdutoInput.focus();
        }
    }

    function handleSelectProd(prod) {
        if (openModalProds) setOpenModalProds(false);
        setAtualProd(prod);
        setBuscaProduto(prod.descricao_produto);
        setArrayProds([]);
        setTimeout(() => {
            let quantidadeSaidaInput = document.getElementById('quantidade_saida');
            if (quantidadeSaidaInput) quantidadeSaidaInput.focus();
        }, 500);
    }

    function handleFinalizar() {
        if (produtosVenda.length > 0 && total_venda > 0) {
            setOpenModalFinalizar(true);
        } else {
            AlertInfo('info', 'Nenhum produto adicionado a venda');
        }
    }

    function calcTotalVenda() {
        if (produtosVenda.length > 0) {
            const tot = produtosVenda.map(i => Number(i.total)).reduce((i, s) => i + s);
            setTotalVenda(tot);
        } else {
            setTotalVenda(0);
        }
    }

    function removeItem(item) {
        AlertConfirm('', 'REMOVER ITEM DA VENDA?', '', 'SIM').then(res => {
            if (res.isConfirmed) {
                const up = produtosVenda.filter(i => i !== item);
                setProdutosVenda(up);
            }
        });
    }

    useEffect(() => {
        calcTotalVenda();
        // eslint-disable-next-line
    }, [produtosVenda]);

    return (
        <Template menuActive="venda">
            <SimpleBackdrop open={openLoad} />
            <ModalListaProduto
                open={openModalProds}
                setOpen={setOpenModalProds}
                rows={arrayProds}
                clickFunc={handleSelectProd}
            />
            <ModalFinalizarVenda
                open={openModalFinalizar}
                setOpen={setOpenModalFinalizar}
                subtotal={total_venda}
                produtos={produtosVenda}
                reset={reset}
            />
            <DrawerFilterVenda
                open={openFilter}
                setOpen={setOpenFilter}
            />

            <Grid container spacing={1}>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4} container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Input
                                id="busca_produto"
                                label="PRODUTO"
                                value={busca_produto}
                                setValue={setBuscaProduto}
                                onKeyUp={e => {
                                    if (e.key === 'Enter') searchProd();
                                }}
                                autoFocus
                            />
                            <Button
                                variant="contained"
                                onClick={searchProd}
                                sx={{ padding: '12px' }}
                            >
                                <FaSearch />
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="quantidade_saida"
                            type="number"
                            upper={false}
                            label="QUANT"
                            value={quantidade_saida}
                            setValue={setQuantidadeSaida}
                            onKeyUp={handleProd}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Paper elevation={6} sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            {produtosVenda.length > 0 && (
                                <List dense sx={{ height: '50vh', overflow: 'auto' }}>
                                    {produtosVenda.map((prod, indexProd) => (
                                        <ListItem key={`listProd.${indexProd}`}>
                                            <ListItemButton onClick={() => removeItem(prod)}>
                                                <ListItemText
                                                    primary={prod.descricao_produto}
                                                    secondary={`${displayNumber3(prod.quantidade_saida)} x ${displayMoney(prod.preco_produto)} = ${displayMoney(prod.total)}`}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                            {produtosVenda.length === 0 && (
                                <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaShoppingBasket size={180} color="#ccc" />
                                </div>
                            )}
                            <CardTotal title="TOTAL" value={displayMoney(total_venda)} />
                        </Paper>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="error"
                            onClick={handleReset}
                            sx={{ height: '100%' }}
                        >
                            <FaTrash />
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={8}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={handleFinalizar}
                        >
                            FINALIZAR
                        </Button>
                    </Grid>

                </Grid>
                <Grid item xs={false} sm={2}></Grid>
                <Grid item xs={12} sm={2} container direction="column" sx={{ gap: 2 }}>
                    {isPermission(51) && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenFilter(true)}
                        >
                            CONSULTAR VENDA
                        </Button>
                    )}
                    {/* {isPermission(52) && (
                        <Button
                            variant="contained"
                            fullWidth
                        // onClick={handleFinalizar}
                        >
                            RELATORIO
                        </Button>
                    )} */}
                </Grid>
            </Grid>
        </Template>
    );
}