import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Template from "../../template/Template";
import Card from '../../components/Cards/Card';
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import NoData from "../../components/NoData/NoData";
import { AlertInfo } from "../../components/Alerts/Alert";
import { displayMoney } from "../../services/displayValue";
import { dateBrlExat, dateBrl, dateEua } from "../../services/date";
import { api } from "../../services/api";

import DrawerFiltroCaixa from "./DrawerFiltroCaixa";
import ModalInfoVenda from "../Venda/ModalInfoVenda";
import ModalInfoParcela from "../Mensalidades/ModalInfoParcela";
import ModalAddContaApagar from "../ContasPagar/ModalAddContaApagar";

export default function Caixa() {
    const [openLoad, setOpenLoad] = useState(false);
    const [entradas, setEntradas] = useState({ q: 0, v: 0 });
    const [saidas, setSaidas] = useState({ q: 0, v: 0 });
    const [rows, setRows] = useState([]);
    const [openFilters, setOpenFilters] = useState(false);
    const [dataCaixa, setDataCaixa] = useState(`${dateBrl()} à ${dateBrl()}`);
    const [openVenda, setOpenVenda] = useState(false);
    const [id_venda, setIdVenda] = useState(0);
    const [openParcela, setOpenParcela] = useState(false)
    const [infoParcela, setInfoParcela] = useState(null);
    const [openConta, setOpenConta] = useState(false);
    const [infoConta, setInfoConta] = useState(null);

    const columns = [
        {
            id: 'n',
            numeric: false,
            label: 'NOME',
            minWidth: '300px'
        },
        {
            id: 'orig',
            numeric: false,
            label: 'ORIGEM',
        },
        {
            id: 'type',
            numeric: false,
            label: 'TIPO',
            component: ({ item }) => <Chip label={item} color={item === 'ENTRADA' ? 'success' : 'error'} />
        },
        {
            id: 'form',
            numeric: false,
            label: 'FORMA',
        },
        {
            id: 'dt',
            numeric: false,
            disablePadding: false,
            label: 'DATA',
            format: dateBrlExat,
            minWidth: '180px'
        },
        {
            id: 'val',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        }
    ];

    function handleClick(item) {
        if (item.id_venda) {
            setIdVenda(item.id_venda);
            setOpenVenda(true);
        } else if (item.id_parcela) {
            setInfoParcela(item);
            setOpenParcela(true);
        } else if (item.id_conta_pagar) {
            setInfoConta(item);
            setOpenConta(true);
        }
    }

    function formatVenda(i) {
        let pay = [];
        if (Number(i.dinheiro > 0)) pay.push('DINHEIRO');
        if (Number(i.pix > 0)) pay.push('PIX');
        if (Number(i.credito > 0)) pay.push('CREDITO');
        if (Number(i.debito > 0)) pay.push('DEBITO');
        if (Number(i.aprazo > 0)) pay.push('A PRAZO');

        return {
            ...i,
            n: Number(i.id_aluno_venda) > 0 ? i.nome_aluno : 'VENDA AVULSA',
            orig: 'VENDA',
            type: 'ENTRADA',
            form: pay.join('/'),
            dt: i.data_venda,
            val: i.total
        };
    }

    async function loadData(start = `${dateEua()} 00:00:00`, end = `${dateEua()} 23:59:59`, origem = 'TODOS', pagamento = 'TODOS') {
        setOpenLoad(true);
        try {
            const data = { start, end };
            const response = await api.post(`/caixa`, data);
            if (response && response.status === 200 && response.data) {
                const { resContas = [], resParcelas = [], resVendas = [] } = response.data;
                let allData = [
                    ...resContas.map(i => ({ ...i, n: i.beneficiario, orig: 'CONTA', type: 'SAIDA', form: i.forma_pagamento, dt: i.data_pagamento, val: i.valor_pago })),
                    ...resParcelas.map(i => ({ ...i, n: i.nome_aluno, orig: 'MENSALIDADE', type: 'ENTRADA', form: i.forma_pagamento, dt: i.pagamento, val: i.valor_parcela })),
                    ...resVendas.map(formatVenda),
                ];
                if (origem !== "TODOS") allData = allData.filter(i => i.orig === origem);
                if (pagamento !== "TODOS") allData = allData.filter(i => String(i.form).includes(pagamento));
                const sortRows = allData.sort((a, b) => {
                    return a['dt'] < b['dt'] ? -1 : a['dt'] > b['dt'] ? 1 : 0;
                });
                setRows(sortRows);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        const inp = rows.filter(i => i.type === 'ENTRADA');
        const out = rows.filter(i => i.type === 'SAIDA');
        setEntradas({ q: inp.length, v: inp.length > 0 ? inp.map(p => Number(p.val)).reduce((i, s) => i + s) : 0 });
        setSaidas({ q: out.length, v: out.length > 0 ? out.map(p => Number(p.val)).reduce((i, s) => i + s) : 0 });
    }, [rows]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="caixa">
            <SimpleBackdrop open={openLoad} />
            <DrawerFiltroCaixa
                open={openFilters}
                setOpen={setOpenFilters}
                loadData={loadData}
                setText={setDataCaixa}
            />

            <ModalInfoVenda
                open={openVenda}
                setOpen={setOpenVenda}
                id_venda={id_venda}
            />
            <ModalInfoParcela
                open={openParcela}
                setOpen={setOpenParcela}
                info={infoParcela}
                setInfo={setInfoParcela}
                loadData={loadData}
            />
            <ModalAddContaApagar
                open={openConta}
                setOpen={setOpenConta}
                info={infoConta}
                setInfo={setInfoConta}
                reload={loadData}

            />

            <Grid container spacing={1} justifyContent="center">
                <Grid item xs={false} sm={9}></Grid>
                <Grid item xs={12} sm={3} justifyContent="flex-end" alignItems="center" container sx={{ gap: 1 }}>
                    <Chip label={dataCaixa} color="primary" />
                    <Fab size="small" color="primary" onClick={() => setOpenFilters(true)} sx={{ zIndex: 1 }}>
                        <SearchIcon />
                    </Fab>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Card
                        title={`ENTRADAS: ${entradas.q}`}
                        label={`R$ ${displayMoney(entradas.v)}`}
                        icon="plus"
                        color='success'
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        title={`SAIDAS: ${saidas.q}`}
                        label={`R$ ${displayMoney(saidas.v)}`}
                        icon="minus"
                        color='error'
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card
                        title={`TOTAL`}
                        label={`R$ ${displayMoney(Number(entradas.v) - Number(saidas.v))}`}
                        icon="dolar"
                        color='primary'
                    />
                </Grid>

                {rows.length > 0 && (
                    <Grid item xs={12}>
                        <TableDataSimple
                            label="Mensalidades"
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                        />
                    </Grid>
                )}

                {rows.length === 0 && !openLoad && (
                    <Grid item xs={12}>
                        <NoData />
                    </Grid>
                )}
            </Grid>
        </Template>
    );
}