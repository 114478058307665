import { useState, useEffect } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Inputs/Input";
import SelectSimple from "../../components/Selects/SelectSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { converterMoeda, displayMoney } from "../../services/displayValue";
import { isDate, dateEua } from "../../services/date";
import { api } from "../../services/api";

export default function ModalAddContaApagar({ open, setOpen, reload, info, setInfo }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [id_conta_pagar, setIdContaPagar] = useState(null);
    const [beneficiario, setBeneficiario] = useState("");
    const [valor_pagar, setValorPagar] = useState("");
    const [vencimento, setVencimento] = useState("");
    const [id_categoria_pagar, setIdCategoriaPagar] = useState("0");
    const [is_renove, setIsRenove] = useState(false);
    const [descricao_adicional, setDescricaoAdicional] = useState("");
    const [forma_pagamento, setFormaPagamento] = useState("DINHEIRO");
    const [valor_pago, setValorPago] = useState(0);
    const [data_pagamento, setDataPagamento] = useState(dateEua());
    const [categorias, setCategorias] = useState([{ value: "0", label: "NEHUMA" }]);
    const [openModalPagar, setOpenModalPagar] = useState(false);

    async function handleSave() {
        if (String(beneficiario).length === 0) return AlertInfo('info', 'PREENCHA O DESTINATARIO');
        if (converterMoeda(valor_pagar) === 0) return AlertInfo('info', 'DIGITE UM VALOR VALIDO');
        if (isDate(vencimento) === null) return AlertInfo('info', 'PREENCHA UM VENCIMENTO VALIDO');
        setOpenLoad(true);
        try {
            const data = {
                beneficiario, valor_pagar: converterMoeda(valor_pagar), id_conta_pagar,
                vencimento, id_categoria_pagar, is_renove: Number(is_renove),
                descricao_adicional, id_academia_pagar: infoLogin.id_academia_usuario
            };
            const channel = id_conta_pagar ? `/updatecontapagar` : `/createcontapagar`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.success) {
                    reload();
                    setOpen(false);
                    AlertInfo('success', 'DADOS GRAVADOS');
                } else if (response.data.error) {
                    AlertInfo('error', response.data.message);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        if(info && info.situacao && info.situacao !== "RECEIVED"){
            AlertConfirm('', 'DELETAR CONTA A PAGAR').then(async res => {
                if (res.isConfirmed) {
                    setOpenLoad(true);
                    try {
                        const response = await api.delete(`/deletecontapagar/${id_conta_pagar}`);
                        if (response && response.status === 200 && response.data) {
                            if (response.data.success) {
                                reload();
                                setOpen(false);
                                AlertInfo('success', 'CONTA DELETADA');
                            } else {
                                AlertInfo('error', response.data.message);
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        AlertInfo('error', 'FALHA NA CONEXÃO');
                    }
                    setOpenLoad(false);
                }
            });
        }
    }

    async function loadCategorias() {
        try {
            const response = await api.get(`/categorias`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) return AlertInfo('error', response.data.message);
                const array = response.data;
                const arrayOptions = array.map(i => ({ value: i.id_categoria, label: i.nome_categoria }));
                setCategorias([{ value: "0", label: "NEHUMA" }, ...arrayOptions]);
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
    }

    async function handlePagar() {
        if (isDate(data_pagamento) === null) return AlertInfo('info', 'DATA DE PAGAMENTO INVALIDA');
        if (converterMoeda(valor_pago) <= 0) return AlertInfo('info', 'VALOR PAGO INVALIDO');
        setOpenLoad(true);
        try {
            const data = {
                id_conta_pagar, data_pagamento, valor_pago: converterMoeda(valor_pago),
                forma_pagamento, situacao: "RECEIVED", update_pagar: dateEua(),
                beneficiario, valor_pagar: converterMoeda(valor_pagar),
                vencimento, id_categoria_pagar, is_renove: Number(is_renove),
                descricao_adicional, id_academia_pagar: infoLogin.id_academia_usuario
            };
            const response = await api.post(`/pagarconta`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.success) {
                    reload();
                    setOpenModalPagar(false);
                    setOpen(false);
                    AlertInfo('success', 'CONTA PAGA');
                } else {
                    AlertInfo('error', 'FALHA NA OPERAÇÃO');
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function handleExtorno() {
        AlertConfirm('', 'EXTORNAR CONTA PAGA').then(async res => {
            if(res.isConfirmed){
                setOpenLoad(true);
                try {
                    const data = {
                        id_conta_pagar, data_pagamento: null, valor_pago: 0,
                        forma_pagamento: "", situacao: moment().isAfter(moment(vencimento))?"OVERDUE":"PENDING", 
                        update_pagar: dateEua(),
                    };
                    const response = await api.post(`/updatecontapagar`, data);
                    if(response && response.status === 200 && response.data){
                        if(response.data.success){
                            reload();
                            setOpen(false);
                            AlertInfo('success', 'CONTA EXTORNADA');
                        }else if(response.data.error){
                            AlertInfo('error', response.data.message);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo('error', 'FALHA NA CONEXÃO');
                }
                setOpenLoad(false);
            }
        });
    }

    function isReadOnly() {
        if(info && info.situacao && info.situacao === "RECEIVED") return true;
        return false;
    }

    useEffect(() => {
        if (open && info && info.id_conta_pagar) {
            setIdContaPagar(info.id_conta_pagar);
            setBeneficiario(info.beneficiario);
            setValorPagar(displayMoney(info.valor_pagar));
            setVencimento(dateEua(info.vencimento));
            setIdCategoriaPagar(info.id_categoria_pagar);
            setIsRenove(Boolean(Number(info.is_renove)));
            setDescricaoAdicional(info.descricao_adicional);
            setFormaPagamento(String(info.forma_pagamento).length > 0 ? info.forma_pagamento : "DINHEIRO");
            setValorPago(displayMoney(Number(info.valor_pago) > 0 ? info.valor_pago : info.valor_pagar));
            setDataPagamento(info.data_pagamento ? dateEua(info.data_pagamento) : dateEua());
        } else {
            setIdContaPagar(null);
            setBeneficiario("");
            setValorPagar("");
            setVencimento("");
            setIdCategoriaPagar("0");
            setIsRenove(false);
            setDescricaoAdicional("");
            setFormaPagamento("DINHEIRO");
            setValorPago("");
            setDataPagamento("");
            setInfo(null);
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        loadCategorias();
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="ADICIONAR CONTA A PAGAR"
            maxWidth="sm"
        >
            <SimpleBackdrop open={openLoad} />
            <Modal
                open={openModalPagar}
                setOpen={setOpenModalPagar}
                title="PAGAR CONTA"
                maxWidth="xs"
            >
                <SimpleBackdrop open={openLoad} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Input
                            type="date"
                            value={data_pagamento}
                            setValue={setDataPagamento}
                            upper={false}
                            label="DATA PAGAMENTO"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            value={valor_pago}
                            setValue={setValorPago}
                            upper={false}
                            label="VALOR PAGO"
                            mask="dinheiro"
                            clickSelect
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectSimple
                            label="FORMA DE PAGAMENTO"
                            value={forma_pagamento}
                            setValue={setFormaPagamento}
                            options={[
                                { value: 'DINHEIRO', label: 'DINHEIRO' },
                                { value: 'CARTÃO CREDITO', label: 'CARTÃO CREDITO' },
                                { value: 'CARTÃO DEBITO', label: 'CARTÃO DEBITO' },
                                { value: 'PIX', label: 'PIX' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={false} sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={handlePagar}
                        >
                            PAGAR
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={2}></Grid>
                </Grid>
            </Modal>

            <Grid container spacing={1}>
                {id_conta_pagar && (
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Input
                                label="ID"
                                value={id_conta_pagar}
                                setValue={() => { }}
                                full={false}
                            />
                            <Fab size="small" color="error" onClick={handleDelete}>
                                <DeleteIcon />
                            </Fab>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Input
                        id="beneficiario"
                        label="DESTINATARIO"
                        value={beneficiario}
                        setValue={setBeneficiario}
                        next="valor_pagar"
                        disabled={isReadOnly()}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="valor_pagar"
                        label="VALOR A PAGAR"
                        value={valor_pagar}
                        setValue={setValorPagar}
                        next="vencimento"
                        mask="dinheiro"
                        disabled={isReadOnly()}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="vencimento"
                        label="VENCIMENTO"
                        value={vencimento}
                        setValue={setVencimento}
                        upper={false}
                        type="date"
                        disabled={isReadOnly()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectSimple
                        label="CATEGORIA"
                        value={id_categoria_pagar}
                        setValue={setIdCategoriaPagar}
                        options={categorias}
                        disabled={isReadOnly()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="descricao_adicional"
                        label="ADICIONAL"
                        value={descricao_adicional}
                        setValue={setDescricaoAdicional}
                        disabled={isReadOnly()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={is_renove} onChange={() => setIsRenove(!is_renove)} disabled={isReadOnly()} />
                        }
                        label="CONTA RECORRENTE (TODO MÊS)"
                    />
                </Grid>


                {(info === null || info.situacao !== "RECEIVED") && (
                    <>
                        <Grid item xs={false} sm={8}></Grid>
                        <Grid item xs={12} sm={4}>
                            {id_conta_pagar && (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => setOpenModalPagar(true)}
                                >
                                    PAGAR
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={false} sm={2}></Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="success"
                                onClick={handleSave}
                            >
                                SALVAR
                            </Button>
                        </Grid>
                        <Grid item xs={false} sm={2}></Grid>
                    </>
                )}
                {info && info.situacao === "RECEIVED" && (
                    <>
                        <Grid item xs={12} sm={4}>
                            <Input
                                type="date"
                                value={data_pagamento}
                                setValue={setDataPagamento}
                                upper={false}
                                label="DATA PAGAMENTO"
                                disabled={isReadOnly()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Input
                                value={valor_pago}
                                setValue={setValorPago}
                                upper={false}
                                label="VALOR PAGO"
                                disabled={isReadOnly()}
                                mask="dinheiro"
                                clickSelect
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SelectSimple
                                label="FORMA DE PAGAMENTO"
                                value={forma_pagamento}
                                setValue={setFormaPagamento}
                                disabled={isReadOnly()}
                                options={[
                                    { value: 'DINHEIRO', label: 'DINHEIRO' },
                                    { value: 'CARTÃO CREDITO', label: 'CARTÃO CREDITO' },
                                    { value: 'CARTÃO DEBITO', label: 'CARTÃO DEBITO' },
                                    { value: 'PIX', label: 'PIX' },
                                ]}
                            />
                        </Grid>

                        <Grid item xs={false} sm={2}></Grid>
                        <Grid item xs={12} sm={8}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={handleExtorno}
                            >
                                EXTORNAR
                            </Button>
                        </Grid>
                        <Grid item xs={false} sm={2}></Grid>
                    </>
                )}
            </Grid>
        </Modal>
    );
}