import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Template from "../../template/Template";
import CardButton from '../../components/CardButton/CardButton';
import Planos from './Planos';

export default function Definir() {
    const [openModalPlanos, setOpenModalPlanos] = useState(false);
    
    return(
        <Template menuActive="definir">            
            <Planos
                open={openModalPlanos}
                setOpen={setOpenModalPlanos}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                    <CardButton label="PLANOS" icon='fileDolar' onClick={() => setOpenModalPlanos(true)} />
                </Grid>
            </Grid>
        </Template>
    );
}