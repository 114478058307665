import { FaTimes } from 'react-icons/fa';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function DrawerApp({open, setOpen, title, children}) {

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(false);
    };

    const list = () => (
        <div
            role="presentation"
            style={{width: 'auto', maxWidth: 450, minWidth: 310}}
        >
            <List>
                <ListItem>
                    <ListItemText primary={title} />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer()} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                { children }
            </List>
        </div>
    );

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer()}
            sx={{zIndex: 900}}
        >
            {list()}
        </Drawer>
    );
}