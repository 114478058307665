import { useState, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FaDumbbell, FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CakeIcon from '@mui/icons-material/Cake';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Template from "../../template/Template";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import { displayPercent } from '../../services/displayValue';
import { dateBrl, endMes, startMes } from '../../services/date';
import isPermission from '../../services/permission';

export default function Home() {
    const theme = useTheme();
    const [openLoad, setOpenLoad] = useState(false);
    const [dataParcelas, setDataParcelas] = useState([
        { id: 0, value: 1, label: 'MENSALIDADES', total: 1, color: theme.palette.info.main },
    ]);
    const [dataMasc, setDataMasc] = useState([0, 0, 0, 0, 0, 0]);
    const [dataFemi, setDataFemi] = useState([0, 0, 0, 0, 0, 0]);
    const [qMas, setQmas] = useState(0);
    const [qFem, setQfem] = useState(0);
    const [happyNow, setHappyNow] = useState([]);
    const [happyNext, setHappyNext] = useState([]);
    const [newAlunos, setNewAlunos] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [vendas, setVendas] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [dataMes, setDataMes] = useState(startMes());

    function loadDataParcelas(array) {
        if (isPermission(10)) {
            const isOverdue = array.filter(i => i.situacao === 'OVERDUE');
            const isPending = array.filter(i => i.situacao === 'PENDING');
            const isReceived = array.filter(i => i.situacao === 'RECEIVED');
            setDataParcelas([
                { id: 0, value: isOverdue.length, label: 'EM ATRASO', total: array.length, color: theme.palette.error.main },
                { id: 1, value: isPending.length, label: 'PENDENTE', total: array.length, color: theme.palette.primary.main },
                { id: 2, value: isReceived.length, label: 'RECEBIDO', total: array.length, color: theme.palette.success.main },
            ]);
        }
    }

    function loadDataMascFemi(array) {
        setQmas(array.filter(a => a.sexo === 'M').length);
        setQfem(array.filter(a => a.sexo === 'F').length);
        const i10 = array.filter(a => moment().diff(moment(a.data_nascimento), 'years') > 9 && moment().diff(moment(a.data_nascimento), 'years') < 20)
        const i20 = array.filter(a => moment().diff(moment(a.data_nascimento), 'years') > 19 && moment().diff(moment(a.data_nascimento), 'years') < 30)
        const i30 = array.filter(a => moment().diff(moment(a.data_nascimento), 'years') > 29 && moment().diff(moment(a.data_nascimento), 'years') < 40)
        const i40 = array.filter(a => moment().diff(moment(a.data_nascimento), 'years') > 39 && moment().diff(moment(a.data_nascimento), 'years') < 50)
        const i50 = array.filter(a => moment().diff(moment(a.data_nascimento), 'years') > 49 && moment().diff(moment(a.data_nascimento), 'years') < 60)
        const i60 = array.filter(a => moment().diff(moment(a.data_nascimento), 'years') > 60);
        setDataMasc([
            i10.filter(a => a.sexo === "M").length,
            i20.filter(a => a.sexo === "M").length,
            i30.filter(a => a.sexo === "M").length,
            i40.filter(a => a.sexo === "M").length,
            i50.filter(a => a.sexo === "M").length,
            i60.filter(a => a.sexo === "M").length,
        ]);
        setDataFemi([
            i10.filter(a => a.sexo === "F").length,
            i20.filter(a => a.sexo === "F").length,
            i30.filter(a => a.sexo === "F").length,
            i40.filter(a => a.sexo === "F").length,
            i50.filter(a => a.sexo === "F").length,
            i60.filter(a => a.sexo === "F").length,
        ]);
    }

    function loadDataHappyBirthday(array) {
        const hnow = array.filter(a => moment(a.data_nascimento).format('DD-MM') === moment().format('DD-MM'));
        const hnex = array.filter(a => moment(`${moment().format('YYYY')}-${moment(a.data_nascimento).format('MM-DD')}`).isBetween(moment(), moment().add(5, 'days')));
        setHappyNow(hnow);
        setHappyNext(hnex);
    }

    function getNewAlunos(array) {
        setNewAlunos([
            array.filter(a => moment(a.create_aluno).isBetween(startMes(moment().subtract(6, 'months')), endMes(moment().subtract(6, 'months')))).length,
            array.filter(a => moment(a.create_aluno).isBetween(startMes(moment().subtract(5, 'months')), endMes(moment().subtract(5, 'months')))).length,
            array.filter(a => moment(a.create_aluno).isBetween(startMes(moment().subtract(4, 'months')), endMes(moment().subtract(4, 'months')))).length,
            array.filter(a => moment(a.create_aluno).isBetween(startMes(moment().subtract(3, 'months')), endMes(moment().subtract(3, 'months')))).length,
            array.filter(a => moment(a.create_aluno).isBetween(startMes(moment().subtract(2, 'months')), endMes(moment().subtract(2, 'months')))).length,
            array.filter(a => moment(a.create_aluno).isBetween(startMes(moment().subtract(1, 'months')), endMes(moment().subtract(1, 'months')))).length,
            array.filter(a => moment(a.create_aluno).isBetween(startMes(), endMes())).length,
        ]);
    }

    function loadDataVendas(array) {
        if (isPermission(11)) {
            setVendas([
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(moment().subtract(6, 'months')), endMes(moment().subtract(6, 'months')))).length,
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(moment().subtract(5, 'months')), endMes(moment().subtract(5, 'months')))).length,
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(moment().subtract(4, 'months')), endMes(moment().subtract(4, 'months')))).length,
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(moment().subtract(3, 'months')), endMes(moment().subtract(3, 'months')))).length,
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(moment().subtract(2, 'months')), endMes(moment().subtract(2, 'months')))).length,
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(moment().subtract(1, 'months')), endMes(moment().subtract(1, 'months')))).length,
                array.filter(ven => moment(ven.data_venda).isBetween(startMes(), endMes())).length,
            ]);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/dashboard`);
            if (response && response.status === 200 && response.data) {
                if (response.data.getParcelas) loadDataParcelas(response.data.getParcelas);
                if (response.data.getVendas) loadDataVendas(response.data.getVendas);
                if (response.data.getAlunos) {
                    loadDataMascFemi(response.data.getAlunos);
                    loadDataHappyBirthday(response.data.getAlunos);
                    getNewAlunos(response.data.getAlunos);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({stopLoad: true})
            window.ReactNativeWebView.postMessage(dataPost);
        }
    }

    async function handleDataParcela(change) {
        setOpenLoad(true);
        setDataMes();
        try {
            const start = change === 'add' ? startMes(moment(dataMes).add(1, 'month')) : startMes(moment(dataMes).subtract(1, 'month'));
            const end = change === 'add' ? endMes(moment(dataMes).add(1, 'month')) : endMes(moment(dataMes).subtract(1, 'month'));
            const search = {start, end};
            const response = await api.post(`/parcelasperiodo`, search);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) loadDataParcelas(response.data);
                setDataMes(start);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    function GraficMensalidades() {
        return (
            <PieChart
                series={[
                    {
                        data: dataParcelas,
                        arcLabel: (item) => displayPercent((item.value * 100) / item.total),
                    },
                ]}
                slotProps={{ legend: { hidden: true } }}
                height={200}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                    },
                }}
            />
        );
    }

    function GraficoIdade() {
        return (
            <BarChart
                xAxis={[{ scaleType: 'band', data: ['10 à 19', '20 à 29', '30 à 39', '40 à 49', '50 à 59', '60 ou +'] }]}
                series={[{ data: dataMasc, label: 'MASCULINO' }, { data: dataFemi, label: 'FEMININO' }]}
                colors={[theme.palette.info.main, theme.palette.error.main]}
                height={300}
            />
        );
    }

    function GraficoSexo() {
        return (
            <Gauge
                height={100}
                value={qMas}
                valueMax={Number(qMas) + Number(qFem)}
                startAngle={-100}
                endAngle={100}
                text={({ value, valueMax }) => `${value}/${valueMax - value}`}
                sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 22,
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                        fill: theme.palette.info.main,
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                        fill: theme.palette.error.main,
                    },
                }}
            />
        );
    }

    function HappyBirthday() {
        return (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {happyNow.length > 0 && (
                    <Divider>
                        <Chip label="HOJE" size="small" color="success" />
                    </Divider>
                )}
                {happyNow.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: theme.palette.success.main }}>
                                <CakeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.nome_aluno} secondary={dateBrl(item.data_nascimento)} />
                    </ListItem>
                ))}

                <Divider>
                    <Chip label="PROXIMOS 5 DIAS" size="small" color="primary" />
                </Divider>
                {happyNext.length === 0 && (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                                <EventBusyIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="NENHUM ANIVERSARIANTE NOS PROXIMOS DIAS :(" />
                    </ListItem>
                )}

                {happyNext.map((item, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                                <CakeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.nome_aluno} secondary={dateBrl(item.data_nascimento)} />
                    </ListItem>
                ))}
            </List>
        );
    }

    function GraficoTimeLine({ pData, color }) {
        const xLabels = [
            moment().subtract(6, 'months').locale('pt-br').format('MMM').toUpperCase(),
            moment().subtract(5, 'months').locale('pt-br').format('MMM').toUpperCase(),
            moment().subtract(4, 'months').locale('pt-br').format('MMM').toUpperCase(),
            moment().subtract(3, 'months').locale('pt-br').format('MMM').toUpperCase(),
            moment().subtract(2, 'months').locale('pt-br').format('MMM').toUpperCase(),
            moment().subtract(1, 'months').locale('pt-br').format('MMM').toUpperCase(),
            moment().locale('pt-br').format('MMM').toUpperCase(),
        ];
        return (
            <LineChart
                xAxis={[{ scaleType: 'point', data: xLabels }]}
                series={[
                    { data: pData, area: true },
                ]}
                colors={[color]}
                height={370}
            />
        );
    }

    useMemo(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="home">
            <SimpleBackdrop open={openLoad} />

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <FaDumbbell size={32} />
                        <h1 style={{ fontSize: 16 }}>BEM VINDO AO LINK ACADEMIA</h1>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} sx={{ padding: 1 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FaChevronCircleLeft style={{ cursor: 'pointer' }} onClick={() => handleDataParcela('sub')} />
                            <h4>RESUMO DE MENSALIDADES DE {moment(dataMes).locale('pt-br').format('MMMM').toUpperCase()}</h4>
                            <FaChevronCircleRight style={{ cursor: 'pointer' }} onClick={() => handleDataParcela('add')} />
                        </div>
                        <GraficMensalidades />
                    </Paper>
                    <br />
                    <Paper elevation={4} style={{ padding: 1 }}>
                        <h4>GENERO ALUNOS</h4>
                        <GraficoSexo />
                        <br />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} style={{ padding: 2 }}>
                        <h4>NOVOS ALUNOS</h4>
                        <GraficoTimeLine pData={newAlunos} color={theme.palette.success.main} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} style={{ padding: 2 }}>
                        <h4>VENDAS</h4>
                        <GraficoTimeLine pData={vendas} color={theme.palette.primary.main} />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} sx={{ padding: 1 }}>
                        <h4>MEDIA DE IDADE</h4>
                        <GraficoIdade />
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Paper elevation={4} style={{ padding: 2 }}>
                        <h4>ANIVERSARIANTES</h4>
                        <HappyBirthday />
                    </Paper>
                </Grid>
            </Grid>
        </Template>
    );
}