import moment from "moment";

export const isDate = (dt = '') => {
    if(moment(dt).isValid()) return dt;
    return null;
}

export const dateEuaExat = (dt = new Date()) => moment(dt).format("YYYY-MM-DD HH:mm:ss");
export const dateEua = (dt = new Date()) => moment(dt).format("YYYY-MM-DD");

export const dateBrlExat = (dt = new Date()) => moment(dt).format("DD/MM/YYYY HH:mm:ss");
export const dateBrl = (dt = new Date()) => moment(dt).format("DD/MM/YYYY");

export const getHors = (dt = new Date()) => moment(dt).format("HH:mm:ss");

export const startMes = (parseData = new Date(), precision = 'date') => {
    const data = moment(parseData).startOf('month');
    const dataFormat = precision === "datetime" ? dateEuaExat(data) : dateEua(data);
    return dataFormat;
}

export const endMes = (parseData = new Date(), precision = 'date') => {
    const data = moment(parseData).endOf('month');
    const dataFormat = precision === "datetime" ? dateEuaExat(data) : dateEua(data);
    return dataFormat;
}