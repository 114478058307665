import { useEffect, useState } from "react";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from '../../services/api';

export default function DrawerFiltroAlunos({ open, setOpen, setText, setRows, setAllRows }) {
    const [openLoad, setOpenLoad] = useState(false);
    const [busca, setBusca] = useState("0");
    const [bairro, setBairro] = useState("");
    const [telefone, setTelefone] = useState("");
    const [id_plano_aluno, setIdPlanoAluno] = useState(0);
    const [dia_pagamento, setDiaPagamento] = useState(1);
    const [sexo, setSexo] = useState("A");
    const [status_aluno, setStatusAluno] = useState(2);
    const [planos, setPlanos] = useState([{ value: 0, label: '---' }]);

    async function handleSearch() {
        let channel = `/aluno`;
        let data = {};
        let and = {};
        
        if(sexo !== "A") and = {sexo};
        if(status_aluno !== 2) and = {...and, status_aluno};
        
        if(busca === "0"){
            data = and;
        }else if(busca === "1"){
            if(bairro.length === 0) return AlertInfo('info', "PREENCHA UM BAIRRO VALIDO");
            channel = `/searchalunos`;
            data = { column: 'bairro', search: bairro, and };
        }else if(busca === "2") {
            if(telefone.length < 8) return AlertInfo('info', "PREENCHA UM TELEFONE VALIDO");
            data = { telefone, ...and };
        }else if(busca === "3") {
            if(Number(id_plano_aluno) === 0) return AlertInfo('info', "SELECIONE UM PLANO");
            data = { id_plano_aluno, ...and };
        }else if(busca === "4") {
            if(Number(dia_pagamento) <= 0 || Number(dia_pagamento) > 31) return AlertInfo('info', "SELECIONE UM DIA VALIDO");
            data = { dia_pagamento, ...and };
        }
        setOpenLoad(true);
        try {
            const response = await api.post(channel, data);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo('error', response.data.message);
                }else{
                    setRows(response.data);
                    setAllRows(response.data);
                    setOpen(false);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    async function loadData() {
        try {
            const response = await api.post(`/planos`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error){
                    const allP = response.data;
                    const forP = allP.map(p => ({value: p.id_plano, label: p.plano}));
                    setPlanos([{ value: 0, label: '---' }, ...forP]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <SimpleBackdrop open={openLoad} />
            <ListItem>
                <SelectSimple
                    label="BUSCAR POR"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "0", label: '---' },
                        { value: "1", label: 'BAIRRO' },
                        { value: "2", label: 'TELEFONE' },
                        { value: "3", label: 'PLANO' },
                        { value: "4", label: 'DIA PAGAMENTO' },
                    ]}
                />
            </ListItem>

            {busca === "1" && (
                <ListItem>
                    <Input
                        label="BAIRRO"
                        value={bairro}
                        setValue={setBairro}
                    />
                </ListItem>
            )}
            
            {busca === "2" && (
                <ListItem>
                    <Input
                        label="TELEFONE"
                        value={telefone}
                        setValue={setTelefone}
                        mask="telefone"
                    />
                </ListItem>
            )}            

            {busca === "3" && (
                <ListItem>
                    <SelectSimple
                        label="PLANO"
                        value={id_plano_aluno}
                        setValue={setIdPlanoAluno}
                        options={planos}
                    >
                    </SelectSimple>
                </ListItem>
            )}
            
            {busca === "4" && (
                <ListItem>
                    <Input
                        type="number"
                        label="DIA PAGAMENTO"
                        value={dia_pagamento}
                        setValue={setDiaPagamento}
                        upper={false}
                    />
                </ListItem>
            )}

            <ListItem>
                <SelectSimple
                    label="STATUS ALUNO"
                    value={status_aluno}
                    setValue={setStatusAluno}
                    options={[
                        { value: 1, label: 'ATIVOS' },
                        { value: 0, label: 'INATIVOS' },
                        { value: 2, label: 'TODOS' },
                    ]}
                >
                </SelectSimple>
            </ListItem>

            <ListItem>
                <SelectSimple
                    label="GENERO"
                    value={sexo}
                    setValue={setSexo}
                    options={[
                        { value: "A", label: 'TODOS' },
                        { value: "M", label: 'MASCULINO' },
                        { value: "F", label: 'FEMININO' },
                    ]}
                >
                </SelectSimple>
            </ListItem>

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    FILTRAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}