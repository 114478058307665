import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Modal({ open, setOpen, title, children, content = null, maxWidth = 'xs', zIndex = 999 }) {
    const Content = content ? content : () => <div/>

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            style={{zIndex}}
            maxWidth={maxWidth}
            scroll="paper"
            fullWidth
        >
            <DialogTitle sx={{padding: 0, textAlign:'center'}}>{title}</DialogTitle>
            <DialogContent dividers sx={{padding: 1}}>
                <DialogContentText>
                    <Content />
                </DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>FECHAR</Button>
            </DialogActions>
        </Dialog>
    );
}