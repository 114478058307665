import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { FaFolderOpen, FaUsers, FaDumbbell, FaShoppingBasket } from 'react-icons/fa';
import { FaDollarSign, FaHandHoldingUsd, FaFileInvoiceDollar, FaMoneyBillAlt } from 'react-icons/fa';
import { FaTasks, FaUserLock, FaBoxes } from 'react-icons/fa';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import isPermission from '../../services/permission';

import './style.css';

const SideBar = ({ menuActive }) => {
    const [subMenu, setSubMenu] = useState(sessionStorage.getItem('subMenu') || "");

    const colapseMenu = (expand) => {
        if(subMenu === expand){
            setSubMenu("");
            sessionStorage.setItem('subMenu', "");
        }else{
            setSubMenu(expand);
            sessionStorage.setItem('subMenu', expand);
        }
    }

    const MenuItem = ({ menu = null, sub = '', IconMenu, label = '', itemSubMenu = null, ...rest }) => {
        const active = (menuActive === menu)?'menu-active':'';
    
        return(
            <div className={`menu-item-sidebar ${sub} ${active}`} { ...rest }>
                <div className="link-menu-sidebar">
                    <IconMenu/>
                    <Typography component="h6">{ label }</Typography>
                </div>
                {itemSubMenu && (
                    <div>
                        {(subMenu === itemSubMenu) ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                    </div>
                )}
            </div>
        );
    }

    return(
        <div className="side-area" id="sidebar">
            <div className="header-menu" />
            <Link to="/">
                <MenuItem
                    menu="home"
                    IconMenu={FaTachometerAlt}
                    label="INICIO"
                />
            </Link>

            <MenuItem
                IconMenu={FaFolderOpen}
                label="CADASTROS"
                onClick={() => colapseMenu('cadastros')}
                itemSubMenu="cadastros"
            />
            <Collapse in={(subMenu ===  'cadastros') ? true : false}>
                {isPermission(1) && (
                    <Link to="/usuarios">
                        <MenuItem
                            menu="usuarios"
                            IconMenu={FaUserLock}
                            label="USUARIOS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}

                {isPermission(20) && (
                    <Link to="/alunos">
                        <MenuItem
                            menu="alunos"
                            IconMenu={FaUsers}
                            label="ALUNOS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
                {isPermission(30) && (
                    <Link to="/produtos">
                        <MenuItem
                            menu="produtos"
                            IconMenu={FaBoxes}
                            label="PRODUTOS"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
            </Collapse>

            <MenuItem
                IconMenu={FaDumbbell}
                label="ACADEMIA"
                onClick={() => colapseMenu('escola')}
                itemSubMenu="escola"
            />
            <Collapse in={(subMenu ===  'escola') ? true : false}>
                {isPermission(40) && (
                    <Link to="/definir">
                        <MenuItem
                            menu="definir"
                            IconMenu={FaTasks}
                            label="DEFINIÇÕES"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
                {isPermission(50) && (
                    <Link to="/venda">
                        <MenuItem
                            menu="venda"
                            IconMenu={FaShoppingBasket}
                            label="VENDA"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
            </Collapse>

            <MenuItem
                IconMenu={FaDollarSign}
                label="FINANCEIRO"
                onClick={() => colapseMenu('financeiro')}
                itemSubMenu="financeiro"
            />
            <Collapse in={(subMenu ===  'financeiro') ? true : false}>
                {isPermission(60) && (
                    <Link to="/mensalidades">
                        <MenuItem
                            menu="mensalidades"
                            IconMenu={FaHandHoldingUsd}
                            label="MENSALIDADES"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
                {isPermission(70) && (
                    <Link to="/pagar">
                        <MenuItem
                            menu="pagar"
                            IconMenu={FaFileInvoiceDollar}
                            label="CONTAS A PAGAR"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
                {isPermission(80) && (
                    <Link to="/caixa">
                        <MenuItem
                            menu="caixa"
                            IconMenu={FaMoneyBillAlt}
                            label="CAIXA"
                            sub="menu-submenu"
                        />
                    </Link>
                )}
            </Collapse>            
        </div>
    );
}

export default SideBar;