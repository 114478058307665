import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '../../components/Modal/Modal';
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import { AlertInfo } from '../../components/Alerts/Alert';
import { converterMoeda, displayMoney } from '../../services/displayValue';
import { api } from '../../services/api';

export default function ModalEstoqueProduto({ open, setOpen, id_produto, setQuantidade, setCusto, calc }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [tipomovimento, setTipoMovimento] = useState("1");
    const [quantidade_entrada, setQuantidadeEntrada] = useState("");
    const [preco_custo_entrada, setPrecoCustoEntrada] = useState("");
    const [descricao_entrada, setDescricaoEntrada] = useState("");
    const [quantidade_saida, setQuantidadeSaida] = useState("");
    const [descricao_saida, setDescricaoSaida] = useState("");

    async function handleEstoque() {
        setOpenLoad(true);
        if(tipomovimento === "1"){
            const data = {
                quantidade_entrada, preco_custo_entrada: converterMoeda(preco_custo_entrada),
                descricao_entrada, id_produto_estoque_entrada: id_produto, 
                id_academia_estoque_entrada: infoLogin.id_academia_usuario
            };
            try {
                const response = await api.post(`/estoqueentrada`,data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error){
                        AlertInfo('error', response.data.message);
                    }else if(response.data.success){
                        setQuantidade(q => Number(q) + Number(quantidade_entrada));
                        if(data.preco_custo_entrada > 0){
                            setCusto(displayMoney(data.preco_custo_entrada));
                            calc();
                        }
                        AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                            setOpen(false);
                        });
                    }
                }else{
                    AlertInfo('error', 'FALHA NA OPERAÇÃO');
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA CONEXÃO');
            }
        }else if(tipomovimento === "2"){
            const data = {
                quantidade_saida, descricao_saida, id_produto_estoque_saida: id_produto, 
                id_academia_estoque_saida: infoLogin.id_academia_usuario
            };
            try {
                const response = await api.post(`/estoquesaida`,data);
                if(response && response.status === 200 && response.data){
                    if(response.data.error){
                        AlertInfo('error', response.data.message);
                    }else if(response.data.success){
                        setQuantidade(q => Number(q) - Number(quantidade_saida));
                        AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                            setOpen(false);
                        });
                    }
                }else{
                    AlertInfo('error', 'FALHA NA OPERAÇÃO');
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA CONEXÃO');
            }
        }
        setOpenLoad(false);
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="MOVIMENTAR ESTOQUE"
            maxWidth='xs'
        >
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SelectSimple
                        label="TIPO MOVIMENTO"
                        value={tipomovimento}
                        setValue={setTipoMovimento}
                        options={[
                            { value: "1", label: "ENTRADA" },
                            { value: "2", label: "SAIDA" },
                        ]}
                    />
                </Grid>

                {tipomovimento === "1" && (
                    <>
                        <Grid item xs={12}>
                            <Input
                                id="quantidade_entrada"
                                label="QUANTIDADE ENTRADA"
                                value={quantidade_entrada}
                                setValue={setQuantidadeEntrada}
                                type="number"
                                upper={false}
                                next="preco_custo_entrada"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="preco_custo_entrada"
                                label="PREÇO DE CUSTO"
                                value={preco_custo_entrada}
                                setValue={setPrecoCustoEntrada}
                                mask="dinheiro"
                                next="descricao_entrada"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="descricao_entrada"
                                label="DESCRIÇÃO"
                                value={descricao_entrada}
                                setValue={setDescricaoEntrada}
                            />
                        </Grid>
                    </>
                )}

                {tipomovimento === "2" && (
                    <>
                        <Grid item xs={12}>
                            <Input
                                id="quantidade_saida"
                                label="QUANTIDADE SAIDA"
                                value={quantidade_saida}
                                setValue={setQuantidadeSaida}
                                type="number"
                                upper={false}
                                next="descricao_saida"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="descricao_saida"
                                label="DESCRIÇÃO SAIDA"
                                value={descricao_saida}
                                setValue={setDescricaoSaida}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color={tipomovimento === '1' ? 'success' : 'error'}
                        onClick={() => handleEstoque()}
                        fullWidth
                    >
                        {tipomovimento === '1' ? 'APLICAR ENTRADA' : 'APLICAR SAIDA'}
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
}