import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import LabelAsaas from '../../components/Labels/LabelAsaas';
import NoData from "../../components/NoData/NoData";
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import { displayMoney } from '../../services/displayValue';
import { dateBrl, dateBrlExat, endMes, startMes } from '../../services/date';
import Card from '../../components/Cards/Card';
import isPermission from '../../services/permission';

import ModalAddContaApagar from './ModalAddContaApagar';
import ModalCategorias from './ModalCategorias';
import DrawerFiltroContas from './DrawerFiltroContas';

export default function ContasPagar() {
    const [openLoad, setOpenLoad] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [dataContas, setDataContas] = useState(`${dateBrl(startMes())} à ${dateBrl(endMes())}`);
    const [rows, setRows] = useState([]);
    const [openModalAddConta, setOpenModalAddConta] = useState(false);
    const [openModalCategorias, setOpenModalCategorias] = useState(false);
    const [infoConta, setInfoConta] = useState(null);
    const [emAtraso, setEmAtraso] = useState([]);
    const [pendentes, setPendentes] = useState([]);
    const [pagas, setPagas] = useState([]);
    const [total, setTotal] = useState([]);
    const columns = [
        {
            id: 'beneficiario',
            numeric: false,
            label: 'DESTINATARIO',
        },
        {
            id: 'situacao',
            numeric: false,
            label: 'STATUS',
            format: () => "",
            component: LabelAsaas
        },
        {
            id: 'vencimento',
            numeric: false,
            label: 'VENCIMENTO',
            format: dateBrl
        },
        {
            id: 'pagamento',
            numeric: false,
            disablePadding: false,
            label: 'PAGAMENTO',
            format: (dt) => dt ? dateBrlExat(dt) : ""
        },
        {
            id: 'nome_categoria',
            numeric: false,
            disablePadding: false,
            label: 'CATEGORIA'
        },
        {
            id: 'valor_pagar',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        }
    ];

    function handleClick(row) {
        setInfoConta(row);
        setOpenModalAddConta(true);
    }

    async function loadData(search = { start: startMes(), end: endMes() }) {
        setOpenLoad(true);
        try {
            const response = await api.post(`/contasperiodo`, search);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    async function loadInfoConta(search = {}, channel = `/getcontas`) {
        setOpenLoad(true);
        try {
            const response = await api.post(channel, search);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        setEmAtraso(rows.filter(p => p.situacao === 'OVERDUE'));
        setPendentes(rows.filter(p => p.situacao === 'PENDING'));
        setPagas(rows.filter(p => p.situacao === 'RECEIVED'));
        setTotal(rows.filter(p => p.situacao !== 'CANCELED'));
    }, [rows]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="pagar">
            <ModalAddContaApagar
                open={openModalAddConta}
                setOpen={setOpenModalAddConta}
                reload={loadData}
                info={infoConta}
                setInfo={setInfoConta}
            />
            <ModalCategorias
                open={openModalCategorias}
                setOpen={setOpenModalCategorias}
            />
            <DrawerFiltroContas
                open={openFilters}
                setOpen={setOpenFilters}
                loadDataPer={loadData}
                loadDataInfo={loadInfoConta}
                setText={setDataContas}
            />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(71) && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenModalAddConta(true)}
                        >
                            ADICIONAR
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setOpenModalCategorias(true)}
                    >
                        CATEGORIAS
                    </Button>
                </Grid>
                <Grid item xs={false} sm={5}></Grid>
                <Grid item xs={12} sm={3} justifyContent="flex-end" alignItems="center" container sx={{ gap: 1 }}>
                    <Chip label={dataContas} color="primary" />
                    <Fab size="small" color="primary" onClick={() => setOpenFilters(true)} sx={{ zIndex: 1 }}>
                        <SearchIcon />
                    </Fab>
                </Grid>

                {isPermission(65) && (
                    <>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`EM ATRASO: ${emAtraso.length}`}
                                label={emAtraso.length > 0 ? `R$ ${displayMoney(emAtraso.map(p => Number(p.valor_pagar)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="atraso"
                                color='error'
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`PENDENTES: ${pendentes.length}`}
                                label={pendentes.length > 0 ? `R$ ${displayMoney(pendentes.map(p => Number(p.valor_pagar)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="pendente"
                                color='primary'
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`PAGAS: ${pagas.length}`}
                                label={pagas.length > 0 ? `R$ ${displayMoney(pagas.map(p => Number(p.valor_pago)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="recebido"
                                color='success'
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`TOTAL: ${total.length}`}
                                label={total.length > 0 ? `R$ ${displayMoney(total.map(p => Number(p.valor_pagar)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="total"
                                color='info'
                            />
                        </Grid>
                    </>
                )}

                {rows.length > 0 && (
                    <Grid item xs={12}>
                        <TableDataSimple
                            label="Mensalidades"
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                            filterFunc={setOpenFilters}
                        />
                    </Grid>
                )}

                {rows.length === 0 && !openLoad && (
                    <Grid item xs={12}>
                        <NoData title="NENHUMA CONTA LOCALIZADA" />
                    </Grid>
                )}
            </Grid>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}