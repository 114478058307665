import Modal from "../../components/Modal/Modal";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { displayId, displayMoney } from "../../services/displayValue";

export default function ModalListaProduto({ open, setOpen, rows, clickFunc }) {
    const columns = [
        {
            id: 'id_produto',
            numeric: false,
            label: 'CODIGO',
            format: displayId,
        },
        {
            id: 'descricao_produto',
            numeric: false,
            disablePadding: false,
            label: 'DESCRIÇÃO',
            minWidth: '300px'
        },
        {
            id: 'quantidade_produto',
            numeric: true,
            disablePadding: false,
            label: 'QUANTIDADE',
        },
        {
            id: 'preco_produto',
            numeric: true,
            disablePadding: false,
            label: 'PREÇO',
            format: displayMoney
        }
    ];

    return(
        <Modal
            open={open}
            setOpen={setOpen}
            title="BUSCA PRODUTOS"
            maxWidth="sm"
        >
            <TableDataSimple
                columns={columns}
                rows={rows}
                handleClick={clickFunc}
            />
        </Modal>
    );
}