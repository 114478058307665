import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Modal from "../../components/Modal/Modal";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import Input from "../../components/Inputs/Input";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";

export default function ModalSearchAlunos({ open, setOpen, select = () => { }, useBusca = null }) {
    const [busca, setBusca] = useState("");
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'matricula_aluno',
            numeric: false,
            label: 'MATRICULA',
        },
        {
            id: 'nome_aluno',
            numeric: false,
            disablePadding: false,
            label: 'NOME ALUNO',
            minWidth: '300px'
        },
        {
            id: 'status_aluno',
            numeric: false,
            disablePadding: false,
            label: 'STATUS',
            format: (cod) => Number(cod) === 1 ? 'ATIVO' : 'INATIVO'
        },
        {
            id: 'telefone',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE',
        }
    ];

    async function handleSearch(fast = null) {
        setOpenLoad(true);
        try {
            const data = { column: 'nome_aluno', search: fast ? fast : busca };
            const response = await api.post(`/searchalunos`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    setRows(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO', 'Tente novamente');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        if (useBusca && useBusca.length > 0) {
            handleSearch(useBusca);
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="BUSCAR ALUNO"
            maxWidth="md"
        >
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Input
                        label="BUSCAR"
                        value={busca}
                        setValue={setBusca}
                        onKeyUp={e => {
                            if(e.key === 'Enter') handleSearch();
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        label="Lista de Alunos"
                        rows={rows}
                        columns={columns}
                        handleClick={select}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}