import { useState } from "react";
import moment from "moment";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import { startMes, endMes, dateBrl } from "../../services/date";

export default function DrawerFiltroCaixa({ open, setOpen, loadData, setText }) {
    const [busca, setBusca] = useState("1");
    const [mes, setMes] = useState(moment().format('YYYY-MM'));
    const [dataInicio, setDataInicio] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [filtro, setFiltro] = useState("TODOS");
    const [pagamentos, setPagamentos] = useState("TODOS");

    async function handleSearch() {
        let start = startMes(mes);
        let end = endMes(mes);
        if (busca === "2") {
            start = dataInicio;
            end = dataFinal;
        } else if (busca === "3") {
            start = dataInicio;
            end = dataInicio;
        }
        start += ` 00:00:00`;
        end += ` 23:59:59`;

        loadData(start, end, filtro, pagamentos);
        setText(`${dateBrl(start)} à ${dateBrl(end)}`);
        setOpen(false);
    }

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <ListItem>
                <SelectSimple
                    label="BUSCA"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "1", label: 'MÊS' },
                        { value: "2", label: 'PERIODO' },
                        { value: "3", label: 'DIA' },
                    ]}
                />
            </ListItem>

            {busca === "1" && (
                <ListItem>
                    <Input
                        type="month"
                        label="MÊS"
                        value={mes}
                        upper={false}
                        setValue={setMes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}
            {busca === "2" && (
                <>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA INCIO"
                            value={dataInicio}
                            setValue={setDataInicio}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA FINAL"
                            value={dataFinal}
                            upper={false}
                            setValue={setDataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                </>
            )}
            {busca === "3" && (
                <ListItem>
                    <Input
                        type="date"
                        label="DATA INCIO"
                        value={dataInicio}
                        setValue={setDataInicio}
                        upper={false}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}

            <ListItem>
                <SelectSimple
                    label="ORIGEM"
                    value={filtro}
                    setValue={setFiltro}
                    options={[
                        { value: "TODOS", label: 'TODAS' },
                        { value: "MENSALIDADE", label: 'MENSALIDADES' },
                        { value: "VENDA", label: 'VENDAS' },
                        { value: "CONTA", label: 'CONTAS' },
                    ]}
                />
            </ListItem>
            
            <ListItem>
                <SelectSimple
                    label="PAGAMENTO"
                    value={pagamentos}
                    setValue={setPagamentos}
                    options={[
                        { value: "TODOS", label: 'TODOS' },
                        { value: "DINHEIRO", label: 'DINHEIRO' },
                        { value: "PIX", label: 'PIX' },
                        { value: "CREDITO", label: 'CREDITO' },
                        { value: "DEBITO", label: 'DEBITO' },
                        { value: "A PRAZO", label: 'A PRAZO' },
                    ]}
                />
            </ListItem>

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    FILTRAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}