import { useEffect, useState } from "react";
import moment from "moment";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import { startMes, endMes, dateBrl } from "../../services/date";
import { api } from '../../services/api';

export default function DrawerFiltroContas({ open, setOpen, loadDataPer, loadDataInfo, setText }) {
    const [busca, setBusca] = useState("1");
    const [mes, setMes] = useState(moment().format('YYYY-MM'));
    const [dataInicio, setDataInicio] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [name, setName] = useState("");
    const [id_categoria_pagar, setIdCategoriaPagar] = useState("0");
    const [categorias, setCategorias] = useState([{ value: "0", label: "NEHUMA" }]);

    async function handleSearch() {
        if(busca === "3"){
            loadDataInfo({search: name}, `/getcontassearch`);
            setText(name);
            setOpen(false);
        }else if(busca === "4") {
            loadDataInfo({id_categoria_pagar});
            const getOption = categorias.filter(cat => Number(cat.value) === Number(id_categoria_pagar));
            setText(getOption[0].label);
            setOpen(false);
        }else{
            let search = {};
            if (busca === "1") search = { start: startMes(mes), end: endMes(mes) };
            else if (busca === "2") search = { start: dataInicio, end: dataFinal };
            loadDataPer(search);
            setText(`${dateBrl(search.start)} à ${dateBrl(search.end)}`);
            setOpen(false);
        }
    }

    async function loadData() {
        try {
            const response = await api.get(`/categorias`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) return;
                const array = response.data;
                const arrayOptions = array.map(i => ({ value: i.id_categoria, label: i.nome_categoria }));
                setCategorias([{ value: "0", label: "NEHUMA" }, ...arrayOptions]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <ListItem>
                <SelectSimple
                    label="BUSCA"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "1", label: 'MÊS' },
                        { value: "2", label: 'PERIODO' },
                        { value: "3", label: 'DESTINATARIO' },
                        { value: "4", label: 'CATEGORIA' },
                    ]}
                />
            </ListItem>

            {busca === "1" && (
                <ListItem>
                    <Input
                        type="month"
                        label="MÊS"
                        value={mes}
                        upper={false}
                        setValue={setMes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}
            {busca === "2" && (
                <>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA INCIO"
                            value={dataInicio}
                            setValue={setDataInicio}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA FINAL"
                            value={dataFinal}
                            upper={false}
                            setValue={setDataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                </>
            )}
            {busca === "3" && (
                <ListItem>
                    <Input
                        label="DESTINATARIO"
                        value={name}
                        setValue={setName}
                        onKeyUp={e => {
                            if(e.key === 'Enter') handleSearch();
                        }}
                    />
                </ListItem>
            )}

            {busca === "4" && (
                <ListItem>
                    <SelectSimple
                        label="CATEGORIA"
                        value={id_categoria_pagar}
                        setValue={setIdCategoriaPagar}
                        options={categorias}
                    >
                    </SelectSimple>
                </ListItem>
            )}

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    FILTRAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}