import { useState, useEffect } from "react";
import { Button, Chip, Divider, Grid } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import InputDisplay from "../../components/Inputs/InputDisplay";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import LabelAsaas from '../../components/Labels/LabelAsaas';
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { displayId, displayMoney } from "../../services/displayValue";
import { dateBrl, dateBrlExat } from "../../services/date";
import { api, apiUrl } from "../../services/api";
import isPermission from "../../services/permission";

export default function ModalInfoVenda({ open, setOpen, id_venda = 0, reset = () => {} }) {
    const [infoVenda, setInfoVenda] = useState(null);
    const [infoProds, setInfoProds] = useState([]);
    const [infoParcelas, setInfoParcelas] = useState([]);
    const [openLoad, setOpenLoad] = useState(false);
    const columnsProdutos = [
        {
            id: 'id_produto_estoque_saida',
            numeric: false,
            label: 'CODIGO',
            format: displayId,
        },
        {
            id: 'descricao_produto',
            numeric: false,
            label: 'DESCRIÇÃO',
            minWidth: '300px'
        },
        {
            id: 'quantidade_saida',
            numeric: true,
            label: 'QUANTIDADE',
        },
        {
            id: 'preco_saida',
            numeric: true,
            label: 'PREÇO',
            format: displayMoney
        },
        {
            id: 'total_saida',
            numeric: true,
            label: 'TOTAL',
            format: displayMoney
        }
    ];
    const columnsParcelas = [
        {
            id: 'valor_parcela',
            numeric: false,
            label: 'VALOR',
            format: displayMoney,
        },
        {
            id: 'vencimento',
            numeric: false,
            label: 'VENCIMENTO',
            format: dateBrl
        },
        {
            id: 'pagamento',
            numeric: false,
            label: 'PAGAMENTO',
            format: (dt) => dt ? dateBrlExat(dt) : ""
        },
        {
            id: 'forma_pagamento',
            numeric: false,
            label: 'FORMA',
        },
        {
            id: 'situacao',
            numeric: false,
            label: 'STATUS',
            format: () => "",
            component: LabelAsaas
        },
    ];

    async function loadData() {
        if (id_venda && Number(id_venda) > 0) {
            setOpenLoad(true);
            try {
                const response = await api.get(`/venda/${id_venda}`);
                console.log(response);
                if (response && response.status === 200 && response.data) {
                    if (response.data.success) {
                        setInfoVenda(response.data.data.dataVenda[0]);
                        setInfoProds(response.data.data.dataProds);
                        setInfoParcelas(response.data.data.dataParcelas);
                    } else if (response.data.error) {
                        AlertInfo('error', response.data.message);
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo('error', 'FALHA NA CONEXÃO');
            }
            setOpenLoad(false);
        }
    }

    function cancelarVenda() {
        if(isPermission(52)){
            AlertConfirm('', "CANCELAR VENDA?").then(async res => {
                if(res.isConfirmed){
                    setOpenLoad(true);
                    try {
                        const response = await api.delete(`/cancelarvenda/${id_venda}`);
                        if(response && response.status === 200 && response.data){
                            if(response.data.success){
                                reset();
                                AlertInfo("success", "VENDA CANCELADA");
                                setOpen(false);
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        AlertInfo("error", "FALHA NA CONEXÃO");
                    }
                    setOpenLoad(false);
                }
            });
        }
    }

    function openReciboVenda() {
        const url = `${apiUrl}/recibovenda/${id_venda}`;
        if(window && window.ReactNativeWebView){
            const dataPost = JSON.stringify({pdf: url})
            window.ReactNativeWebView.postMessage(dataPost);
        }else{
            window.open(url);
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, [id_venda]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={`DETALHES VENDA N° ${displayId(id_venda)}`}
            maxWidth="md"
            // zIndex={7777}
        >
            <SimpleBackdrop open={openLoad} />
            {infoVenda && infoVenda.id_venda && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputDisplay
                            label="ALUNO COMPRADOR"
                            value={infoVenda.nome_aluno}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label="DATA VENDA"
                            value={dateBrlExat(infoVenda.data_venda)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label="USUARIO VENDA"
                            value={infoVenda.nome_usuario}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label="SUBTOTAL"
                            value={displayMoney(infoVenda.subtotal)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label="DESCONTO"
                            value={displayMoney(infoVenda.desconto)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label="ACRESCIMO"
                            value={displayMoney(infoVenda.acrescimo)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label="TOTAL"
                            value={displayMoney(infoVenda.total)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="PAGAMENTO" size="small" />
                        </Divider>
                    </Grid>

                    {Number(infoVenda.dinheiro) > 0 && (
                        <Grid item xs={12} sm={3}>
                            <InputDisplay
                                label="DINHEIRO"
                                value={displayMoney(infoVenda.dinheiro)}
                            />
                        </Grid>
                    )}
                    {Number(infoVenda.pix) > 0 && (
                        <Grid item xs={12} sm={3}>
                            <InputDisplay
                                label="PIX"
                                value={displayMoney(infoVenda.pix)}
                            />
                        </Grid>
                    )}
                    {Number(infoVenda.credito) > 0 && (
                        <Grid item xs={12} sm={3}>
                            <InputDisplay
                                label="CREDITO"
                                value={displayMoney(infoVenda.credito)}
                            />
                        </Grid>
                    )}
                    {Number(infoVenda.debito) > 0 && (
                        <Grid item xs={12} sm={3}>
                            <InputDisplay
                                label="DEBITO"
                                value={displayMoney(infoVenda.debito)}
                            />
                        </Grid>
                    )}
                    {Number(infoVenda.aprazo) > 0 && (
                        <Grid item xs={12} sm={3}>
                            <InputDisplay
                                label="A PRAZO"
                                value={displayMoney(infoVenda.aprazo)}
                            />
                        </Grid>
                    )}

                    {infoParcelas.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="PARCELAS" size="small" />
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <TableDataSimple
                                    columns={columnsParcelas}
                                    rows={infoParcelas}
                                    handleClick={() => { }}
                                    isPagination={false}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="PRODUTOS" size="small" />
                        </Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <TableDataSimple
                            columns={columnsProdutos}
                            rows={infoProds}
                            handleClick={() => { }}
                            isPagination={false}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => openReciboVenda()}
                        >
                            RECIBO VENDA
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={6}></Grid>
                    <Grid item xs={12} sm={3}>
                        {isPermission(52) && (
                            <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={() => cancelarVenda()}
                            >
                                CANCELAR VENDA
                            </Button>
                        )}
                    </Grid>
                </Grid>

            )}
        </Modal>
    );
}