import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FaTrash, FaBoxOpen } from 'react-icons/fa';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Template from "../../template/Template";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import Input from '../../components/Inputs/Input';
import { AlertConfirm, AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { converterMoeda, displayMoney } from '../../services/displayValue';
import ModalEstoqueProduto from './ModalEstoqueProduto';

export default function CadastroProduto() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_academia_produto] = useState(infoLogin.id_academia_usuario || 0);
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalEstoque, setOpenModalEstoque] = useState(false);

    const [id_produto, setIdProduto] = useState(null);
    const [descricao_produto, setDescricaoProduto] = useState("");
    const [codigo_barra, setCodigoBarra] = useState("");
    const [quantidade_produto, setQuantidadeProduto] = useState("");
    const [margem_lucro, setMargemLucro] = useState("");
    const [custo_atual, setCustoAtual] = useState("");
    const [preco_produto, setPrecoProduto] = useState("");
    const [adicional_produto, setAdicionalProduto] = useState("");

    function setDataProduto(data) {
        setIdProduto(data.id_produto)
        setDescricaoProduto(data.descricao_produto)
        setCodigoBarra(data.codigo_barra)
        setQuantidadeProduto(data.quantidade_produto)
        setMargemLucro(data.margem_lucro)
        setCustoAtual(displayMoney(data.custo_atual))
        setPrecoProduto(displayMoney(data.preco_produto));
        setAdicionalProduto(data.adicional_produto);
    }

    async function loadData(cod = null) {
        setOpenLoad(true);
        const id = cod ? cod : searchParms.get('id');
        if (id && isPermission(32)) {
            try {
                const data = { id_produto: id };
                const response = await api.post(`/produto`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setDataProduto(response.data[0]);
                }
            } catch (error) {

            }
        }
        setOpenLoad(false);
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            if (isPermission(31) || isPermission(32)) {
                const data = {
                    id_produto, id_academia_produto, descricao_produto, codigo_barra,
                    quantidade_produto: converterMoeda(quantidade_produto),
                    custo_atual: converterMoeda(custo_atual),
                    margem_lucro, preco_produto: converterMoeda(preco_produto),
                    adicional_produto
                };
                const channel = id_produto ? `/updateproduto` : `/createproduto`;
                const response = await api.post(channel, data);
                let error = 'FALHA NA OPERAÇÃO';
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) {
                        error = response.data.message;
                    } else {
                        setOpenLoad(false);
                        AlertInfo('success', 'Dados Gravados').then(() => {
                            navigate('/produtos');
                        });
                        return;
                    }
                }
                AlertInfo('error', error);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", "FALHA NA CONEXÃO");
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm('', "EXCLUIR PRODUTO?", "A ação não poderá ser desfeita!").then(async res => {
            if (res.isConfirmed) {
                setOpenLoad(true);
                const response = await api.delete(`/deleteproduto/${id_produto}`);
                if (response && response.status && response.data) {
                    if (response.data.success) {
                        AlertInfo("success", "PRODUTO DELETADO").then(() => {
                            navigate('/produtos');
                        });
                    } else if (response.data.error) {
                        AlertInfo("error", response.data.message);
                    }
                } else {
                    AlertInfo("error", "Falha na operação");
                }
                setOpenLoad(false);
            }
        });
    }

    async function isExistProd() {
        if (String(codigo_barra).length > 0 && !id_produto) {
            setOpenLoad(true);
            try {
                const data = { codigo_barra };
                const response = await api.post(`/produto`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setDataProduto(response.data[0]);
                }
            } catch (error) {
                console.log(error);
            }
            setOpenLoad(false);
        }
    }

    function getMargemFromPreco() {
        const custoValue = converterMoeda(custo_atual);
        const precoValue = converterMoeda(preco_produto);
        if (custoValue > 0 & precoValue > 0) {
            let result = precoValue - custoValue;
            result = ((result * 100) / custoValue);
            setMargemLucro(displayMoney(result));
        }
    }

    function getPrecoFromMargem() {
        const custoValue = converterMoeda(custo_atual);
        const margemValue = converterMoeda(margem_lucro);
        if (custoValue > 0 && margemValue > 0) {
            let result = Number((custoValue * margemValue) / 100);
            result = result + custoValue;
            setPrecoProduto(displayMoney(result));
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="produtos">
            <ModalEstoqueProduto
                open={openModalEstoque}
                setOpen={setOpenModalEstoque}
                id_produto={id_produto}
                setQuantidade={setQuantidadeProduto}
                setCusto={setCustoAtual}
                calc={getPrecoFromMargem}
            />
            <br />
            <Paper elevation={3} style={{ padding: 8 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }}>DADOS DO PRODUTO</h2>
                    </Grid>


                    <Grid item xs={12} sm={4}>
                        <Input
                            id="codigo_barra"
                            label="CODIGO DE BARRAS"
                            value={codigo_barra}
                            setValue={setCodigoBarra}
                            next="descricao_produto"
                            onBlur={() => isExistProd()}
                            autoFocus
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Input
                            id="descricao_produto"
                            label="DESCRIÇÃO"
                            value={descricao_produto}
                            setValue={setDescricaoProduto}
                            next="quantidade_produto"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="quantidade_produto"
                            label="QUANTIDADE"
                            value={quantidade_produto}
                            setValue={id_produto ? () => { } : setQuantidadeProduto}
                            next="custo_atual"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="custo_atual"
                            label="CUSTO ATUAL"
                            value={custo_atual}
                            setValue={setCustoAtual}
                            next="margem_lucro"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="margem_lucro"
                            label="MARGEM DE LUCRO"
                            value={margem_lucro}
                            setValue={setMargemLucro}
                            next="preco_produto"
                            onBlur={getPrecoFromMargem}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            id="preco_produto"
                            label="PREÇO DE VENDA"
                            value={preco_produto}
                            setValue={setPrecoProduto}
                            next="adicional_produto"
                            onBlur={getMargemFromPreco}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="adicional_produto"
                            label="ADICIONAL"
                            value={adicional_produto}
                            setValue={setAdicionalProduto}
                        />
                    </Grid>

                    <Grid item xs={false} sm={2}>
                        {id_produto && isPermission(34) && (
                            <Button
                                variant="contained"
                                color='primary'
                                onClick={() => setOpenModalEstoque(true)}
                                fullWidth
                            >
                                <FaBoxOpen /> &nbsp; ESTOQUE
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={false} sm={8}></Grid>
                    <Grid item xs={false} sm={2}>
                        {id_produto && isPermission(33) && (
                            <Button
                                variant="contained"
                                color='error'
                                onClick={() => handleDelete()}
                                fullWidth
                            >
                                <FaTrash /> &nbsp; DELETAR
                            </Button>
                        )}
                    </Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}>
                        <Button
                            variant="contained"
                            color='success'
                            onClick={() => handleSave()}
                            fullWidth
                        >
                            SALVAR PRODUTO
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>

                </Grid>
            </Paper>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}