import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import Template from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';
import LabelAsaas from '../../components/Labels/LabelAsaas';
import Card from '../../components/Cards/Card';
import NoData from "../../components/NoData/NoData";
import Input from '../../components/Inputs/Input';
import { AlertInfo } from '../../components/Alerts/Alert';
import { api } from '../../services/api';
import isPermission from '../../services/permission';
import { displayMoney } from '../../services/displayValue';
import { dateBrl, dateBrlExat, endMes, startMes } from '../../services/date';
import DrawerFiltroMensalidade from './DrawerFiltroMensalidade';
import ModalInfoParcela from './ModalInfoParcela';
import ModalSearchAlunos from '../Alunos/ModalSearchAlunos';

export default function Mensalidades() {
    const [openLoad, setOpenLoad] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [rows, setRows] = useState([]);
    const [openModalParcela, setOpenModalParcela] = useState(false);
    const [infoParcela, setInfoParcela] = useState(null);
    const [emAtraso, setEmAtraso] = useState([]);
    const [pendentes, setPendentes] = useState([]);
    const [recebidas, setRecebidas] = useState([]);
    const [total, setTotal] = useState([]);
    const [buscaRapida, setBuscaRapida] = useState("");
    const [openModalAlunos, setOpenModalAlunos] = useState(false);
    const [dataParcelas, setDataparcelas] = useState(`${dateBrl(startMes())} à ${dateBrl(endMes())}`);
    const columns = [
        {
            id: 'nome_aluno',
            numeric: false,
            label: 'ALUNO(A)',
            minWidth: '300px'
        },
        {
            id: 'situacao',
            numeric: false,
            label: 'STATUS',
            format: () => "",
            component: LabelAsaas
        },
        {
            id: 'vencimento',
            numeric: false,
            label: 'VENCIMENTO',
            format: dateBrl
        },
        {
            id: 'pagamento',
            numeric: false,
            disablePadding: false,
            label: 'PAGAMENTO',
            format: (dt) => dt ? dateBrlExat(dt) : ""
        },
        {
            id: 'usuario_recebedor',
            numeric: false,
            disablePadding: false,
            label: 'USUARIO'
        },
        {
            id: 'forma_pagamento',
            numeric: false,
            disablePadding: false,
            label: 'FORMA PAGO'
        },
        {
            id: 'valor_parcela',
            numeric: true,
            label: 'VALOR R$',
            format: displayMoney
        }
    ];

    function handleClick(row) {
        setInfoParcela(row);
        setOpenModalParcela(true);
    }

    async function loadData(search = { start: startMes(), end: endMes() }) {
        setOpenLoad(true);
        try {
            const response = await api.post(`/parcelasperiodo`, search);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    async function handleFilter() {
        setOpenModalAlunos(true);
        let buscaRapidaInput = document.getElementById('buscaRapida');
        if(buscaRapidaInput) buscaRapidaInput.blur();
    }

    function handleSelectAluno(aluno) {
        setOpenModalAlunos(false);
        loadInfoParcela({id_aluno_parcela: aluno.id_aluno});
    }

    async function loadInfoParcela(search = {}) {
        setOpenLoad(true);
        try {
            const response = await api.post(`/parcelas`, search);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        setEmAtraso(rows.filter(p => p.situacao === 'OVERDUE'));
        setPendentes(rows.filter(p => p.situacao === 'PENDING'));
        setRecebidas(rows.filter(p => p.situacao === 'RECEIVED'));
        setTotal(rows.filter(p => p.situacao !== 'CANCELED'));
    }, [rows]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="mensalidades">
            <DrawerFiltroMensalidade
                open={openFilters}
                setOpen={setOpenFilters}
                loadDataPer={loadData}
                loadInfoParcela={loadInfoParcela}
                setText={setDataparcelas}
            />
            <ModalInfoParcela
                open={openModalParcela}
                setOpen={setOpenModalParcela}
                info={infoParcela}
                setInfo={setInfoParcela}
                loadData={loadData}
            />
            <ModalSearchAlunos
                open={openModalAlunos}
                setOpen={setOpenModalAlunos}
                select={handleSelectAluno}
                useBusca={buscaRapida}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="buscaRapida"
                        label="BUSCAR"
                        value={buscaRapida}
                        setValue={setBuscaRapida}
                        onKeyUp={e => {
                            if (e.key === 'Enter') handleFilter();
                        }}
                    />
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={3} justifyContent="flex-end" alignItems="center" container sx={{ gap: 1 }}>
                    <Chip label={dataParcelas} color="primary" />
                    <Fab size="small" color="primary" onClick={() => setOpenFilters(true)} sx={{ zIndex: 1 }}>
                        <SearchIcon />
                    </Fab>
                </Grid>
                {isPermission(65) && (
                    <>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`EM ATRASO: ${emAtraso.length}`}
                                label={emAtraso.length > 0 ? `R$ ${displayMoney(emAtraso.map(p => Number(p.valor_parcela)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="atraso"
                                color='error'
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`PENDENTES: ${pendentes.length}`}
                                label={pendentes.length > 0 ? `R$ ${displayMoney(pendentes.map(p => Number(p.valor_parcela)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="pendente"
                                color='primary'
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`RECEBIDOS: ${recebidas.length}`}
                                label={recebidas.length > 0 ? `R$ ${displayMoney(recebidas.map(p => Number(p.valor_parcela)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="recebido"
                                color='success'
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Card
                                title={`TOTAL: ${total.length}`}
                                label={total.length > 0 ? `R$ ${displayMoney(total.map(p => Number(p.valor_parcela)).reduce((i, s) => i + s))}` : 'R$ 00,00'}
                                icon="total"
                                color='info'
                            />
                        </Grid>
                    </>
                )}

                {rows.length > 0 && (
                    <Grid item xs={12}>
                        <TableDataSimple
                            label="Mensalidades"
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                            filterFunc={setOpenFilters}
                        />
                    </Grid>
                )}

                {rows.length === 0 && !openLoad && (
                    <Grid item xs={12}>
                        <NoData />
                    </Grid>
                )}
            </Grid>

            <SimpleBackdrop open={openLoad} />
        </Template>
    );
}