import { createTheme, ThemeProvider } from '@mui/material/styles';

const colorTheme = createTheme({
    palette: {
        primary: {
            main: '#091114',
        },
        secondary: {
            main: '#edf2ff',
        },
        error: {
            main: '#a71a1a'
        },
        success: {
            main: '#00a65a'
        },
        warning: {
            main: '#ede436'
        },
        info: {
            main: '#1500ff'
        }
    },
});

export default function MaterialTheme({ children }) {
    return (
        <ThemeProvider theme={colorTheme}>
            {children}
        </ThemeProvider>
    );
}